import { useState } from 'react';

const useWorkspaceInvitationEmail = (workspace) => {
    const [isLoading, setIsLoading] = useState(false);

    const sendInvitationEmail = async (userData, invitationToken) => {
        console.log('Starting email sending process...', { userData, workspace });
        
        if (!workspace || !workspace.name) {
            console.error('Workspace data is missing:', workspace);
            return { 
                success: false, 
                message: 'Error: Workspace data not available' 
            };
        }

        setIsLoading(true);
        try {
            const emailData = {
                to: userData.email,
                from: 'no-reply@knowhub.app',
                subject: `Invitación al workspace ${workspace.name} - KnowHub`,
                text: `Has sido invitado a unirte al workspace "${workspace.name}" en KnowHub.`,
                verificationCode: invitationToken,
                html: `
                    <html>
                        <body style="display: flex; flex-direction: column; align-items: center;">
                            <div style="font-family: Intel, sans-serif; max-width: 376px; padding: 20px;">
                                <h1 style="color: #000000; font-size: 24px; margin-bottom: 32px;">KnowHub</h1>
                                
                                <div style="margin-bottom: 32px;">
                                    <p style="color: #000000; font-size: 16px; margin-bottom: 12px;">
                                        Hello${userData.name ? ' ' + userData.name : ''},
                                    </p>
                                    
                                    <p style="color: #000000; font-size: 16px; margin-bottom: 24px;">
                                        You have been invited to join the workspace "${workspace.name}" in KnowHub.
                                    </p>
                                    
                                    <div style="margin: 32px 0;">
                                        <a href="${process.env.REACT_APP_FRONTEND_URL}/guest-register/${invitationToken}"
                                           style="background-color: #703BEF; color: white; padding: 12px 24px; 
                                                  border-radius: 4px; text-decoration: none; display: inline-block;">
                                            Accept invitation
                                        </a>
                                    </div>
                                    
                                    <p style="color: #131314; font-size: 14px;">
                                        This invitation link will expire in 7 days. If the link has expired, 
                                        please contact the workspace administrator for a new invitation.
                                    </p>
                                </div>
                                
                                <p style="color: #606669; font-size: 14px; margin-bottom: 32px;">
                                    KnowHub is a tool to making knowledge base articles faster than ever.
                                </p>
                                
                                <a href="https://knowhub.app" style="color: #703BEF; text-decoration: none; font-size: 14px;">
                                    knowhub.app
                                </a>
                            </div>
                        </body>
                    </html>
                `
            };

            console.log('Sending email with data:', emailData);

            const response = await fetch(`${process.env.REACT_APP_API_URL}/send-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(emailData)
            });

            console.log('Email API response status:', response.status);
            const data = await response.json();
            console.log('Email API response data:', data);

            if (!response.ok) {
                throw new Error(data.message || 'Error sending invitation email');
            }

            return { 
                success: true, 
                message: 'Invitation sent successfully',
                timestamp: Date.now()
            };

        } catch (error) {
            console.error('Error in sendInvitationEmail:', error);
            
            if (error.message.includes('401') || error.message.includes('403')) {
                localStorage.removeItem('token');
                window.location.href = '/login';
                return { 
                    success: false, 
                    message: 'Session expired. Please login again.' 
                };
            }

            return { 
                success: false, 
                message: error.message || 'Error sending invitation email',
                error: error 
            };
        } finally {
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        sendInvitationEmail
    };
};

export default useWorkspaceInvitationEmail; 