import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Articles from './components/Articles/ArticlesIndex';
import ArticleDetail from './components/Article/ArticleDetail';
import Settings from './components/Settings/Settings';
import Login from './components/UserRegister/Login/Login';
import Signup from './components/UserRegister/Signup';
import SignupWorkspace from './components/UserRegister/Signup/SignupWorkspace';
import SignupLanguages from './components/UserRegister/Signup/SignupLanguages';
import Landing from './components/UserRegister/Landing';
import User from './components/Settings/User/User';
import EmailValidationRoute from './components/UserRegister/Signup/EmailValidation/EmailValidationRoute';
import GuestSignup from './components/UserRegister/GuestRegister/GuestSignup';
import './App.css';

import { WorkspaceProvider } from './context/WorkspaceContext';
import ProtectedRoute from './components/ProtectedRoute';
import WorkspaceSelector from './components/WorkspaceSelector/WorkspaceSelector';


function App() {
    return (
        <Router>
            <WorkspaceProvider>
                <Routes>
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/SignupWorkspace" element={<SignupWorkspace />} />
                    <Route path="/signup-languages" element={<SignupLanguages />} />
                    <Route path="/email-validation" element={<EmailValidationRoute />} />
                    <Route path="/" element={<Landing />} />
                    <Route path="/home" element={
                        <ProtectedRoute>
                            <Home />
                        </ProtectedRoute>
                    } />
                    <Route path="/articles" element={
                        <ProtectedRoute>
                            <Articles />
                        </ProtectedRoute>
                    } />
                    <Route path="/articles/:id" element={
                        <ProtectedRoute>
                            <ArticleDetail />
                        </ProtectedRoute>
                    } />
                    <Route path="/settings/*" element={
                        <ProtectedRoute>
                            <Settings />
                        </ProtectedRoute>
                    } />
                    <Route path="/user" element={
                        <ProtectedRoute>
                            <User />
                        </ProtectedRoute>
                    } />
                    <Route path="/guest-register/:token" element={<GuestSignup />} />
                    <Route path="/workspace-selector" element={
                        <ProtectedRoute skipWorkspaceValidation={true}>
                            <WorkspaceSelector />
                        </ProtectedRoute>
                    } />
                </Routes>
            </WorkspaceProvider>
        </Router>
    );
}

export default App;
