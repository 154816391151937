import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../Signup.css';
import '../../../styles/Button.css';
import '../../../styles/Input.css';
import { GoogleLogin } from '@react-oauth/google';

const GuestSignup = () => {
    const { token } = useParams();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [workspaceInfo, setWorkspaceInfo] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const validateInvitation = useCallback(async () => {
        try {
            setLoading(true);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/workspace/members/validate-invitation/${token}`, {
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            });
            
            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.message || 'Error validating invitation');
            }

            const data = await response.json();
            
            if (data.isExistingUser) {
                navigate(`/login?invitation=${token}`);
                return;
            }

            setEmail(data.email);
            setWorkspaceInfo(data.workspaceInfo);
        } catch (error) {
            setErrorMessage(error.message || 'Invitation invalid or expired');
        } finally {
            setLoading(false);
        }
    }, [token, navigate]);

    useEffect(() => {
        validateInvitation();
    }, [validateInvitation]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/guest-signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    name,
                    email,
                    password,
                    invitationToken: token
                })
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Error during registration');
            }

            localStorage.setItem('token', data.token);
            navigate('/home');
        } catch (err) {
            setError(err.message || 'Error during registration');
        } finally {
            setLoading(false);
        }
    };

    const handleGoogleSuccess = async (credentialResponse) => {
        try {
            setErrorMessage('');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/google-guest-signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ 
                    credential: credentialResponse.credential,
                    invitationToken: token
                }),
            });

            const data = await response.json();
            
            if (response.ok && data.token) {
                localStorage.setItem('token', data.token);
                
                if (data.user && data.user.workspaceId) {
                    localStorage.setItem('currentWorkspace', data.user.workspaceId);
                }
                
                navigate('/home');
            } else {
                setErrorMessage(data.message || 'Error during Google registration');
            }
        } catch (error) {
            setErrorMessage('An error occurred. Please try again.');
        }
    };

    if (loading) {
        return (
            <div className="signup-container">
                <Helmet>
                    <title>Join Workspace | KnowHub</title>
                </Helmet>
                <div className="signup-form">
                    <h2 className="signup-title">Cargando...</h2>
                </div>
            </div>
        );
    }

    if (errorMessage) {
        return (
            <div className="signup-container">
                <Helmet>
                    <title>Join Workspace | KnowHub</title>
                </Helmet>
                <div className="signup-form">
                    <h2 className="signup-title">Invitation Error</h2>
                    <p className="error-message">{errorMessage}</p>
                </div>
            </div>
        );
    }

    return (
        <div className="signup-container">
            <Helmet>
                <title>Join Workspace | KnowHub</title>
            </Helmet>
            <form className="signup-form" onSubmit={handleSubmit}>
                <h2 className="signup-title">
                    {workspaceInfo?.name 
                        ? `Únete a ${workspaceInfo.name}`
                        : 'Completa tu registro'
                    }
                </h2>
                
                {error && (
                    <div className="error-message">
                        {error}
                    </div>
                )}

                <div className="input-group">
                    <label className="input-group-label" htmlFor="signup-email">
                        Email
                    </label>
                    <input
                        className="input"
                        type="email"
                        id="signup-email"
                        value={email}
                        disabled
                        required
                    />
                </div>

                <div className="input-group">
                    <label className="input-group-label" htmlFor="signup-name">
                        Tu nombre
                    </label>
                    <input
                        className="input"
                        type="text"
                        id="signup-name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        autoComplete="name"
                        placeholder="John Doe"
                    />
                </div>

                <div className="input-group">
                    <label className="input-group-label" htmlFor="signup-password">
                        Contraseña
                    </label>
                    <input
                        className="input"
                        type="password"
                        id="signup-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        autoComplete="new-password"
                        placeholder="********"
                    />
                </div>

                <button 
                    className="btn btn-fill-primary"
                    type="submit"
                    disabled={loading}
                >
                    Completar Registro
                </button>

                <div className="social-login-container">
                    <p className="social-login-text">O</p>
                    <div className="social-login">
                        <GoogleLogin
                            onSuccess={handleGoogleSuccess}
                            onError={() => {
                                console.error('Login Failed');
                                setErrorMessage('Error during Google login');
                            }}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default GuestSignup; 