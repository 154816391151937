import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { WorkspaceContext } from '../../../context/WorkspaceContext';
import './UpgradeBannerLanguage.css';
import { Languages } from 'lucide-react';

const UpgradeBannerLanguage = ({ languageLimit }) => {
    const navigate = useNavigate();
    const { workspaceData } = useContext(WorkspaceContext);

    const handleUpgradeClick = () => {
        navigate('/settings/plan-and-billing');
    };

    // Si no hay datos del workspace o el plan no es starter, no mostramos nada
    if (!workspaceData || workspaceData.plan !== 'starter') {
        return null;
    }

    return (
        <div className="language-banner">
            <div className="language-banner-content">
                <div className="language-banner-text">
                    <h2>Do you need more than {languageLimit} languages?</h2>
                    <p>
                        Add more languages to your space and 
                        enjoy a fast and easy way to create articles 
                        in multiple languages for your product or website.
                    </p>
                    <button 
                        className="btn btn-fill-primary"
                        onClick={handleUpgradeClick}
                    >
                        Upgrade
                    </button>
                </div>
                <div className="language-banner-icon">
                <Languages size={135} />
                </div>
            </div>
        </div>
    );
};

export default UpgradeBannerLanguage;
