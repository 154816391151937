import React from 'react';
import '../../../../styles/Modal.css';

const TranslationErrorModal = ({ showModal, closeModal, handleTryAgain }) => {
  if (!showModal) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Translation Error</h2>
        <p>An error occurred during the translation process.</p>
        <div className="modal-actions">
          <button className="btn btn-flat-secondary" onClick={closeModal}>
            Cancel
          </button>
          <button className="btn btn-fill-primary" onClick={handleTryAgain}>
            Try Again
          </button>
        </div>
      </div>
    </div>
  );
};

export default TranslationErrorModal; 