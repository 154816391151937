import React, { useEffect, useState } from 'react';

const GeneratedContent = ({ articleId }) => {
  const [loadingContent, setLoadingContent] = useState(true);
  const [content, setContent] = useState('');

  useEffect(() => {
    const fetchGeneratedData = async () => {
      try {
        console.log('Fetching article:', articleId);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/articles/${articleId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          console.error('Response status:', response.status);
          throw new Error('Failed to fetch generated data');
        }

        const data = await response.json();
        if (data.article) {
          setContent(data.article.content || '');
          
          if (data.isContentGenerated) {
            setLoadingContent(false);
          }
        }
      } catch (error) {
        setLoadingContent(false);
      }
    };

    fetchGeneratedData();
    const intervalId = setInterval(fetchGeneratedData, 5000);

    return () => clearInterval(intervalId);
  }, [articleId]);

  return (
    <div className="generated-content">
      <h2>Generated Content</h2>
      {loadingContent ? (
        <p>Loading content...</p>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: content }} />
      )}
    </div>
  );
};

export default GeneratedContent;
