import React from 'react';
import '../../../../styles/Modal.css';
import '../../../../styles/Button.css';

const DeleteMemberModal = ({ member, onConfirm, onCancel }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Delete Member</h2>
        <p>
          Are you sure you want to delete {member.name || member.email} from the workspace?
          This action cannot be undone.
        </p>
        <div className="modal-actions">
          <button 
            className="btn btn-outline-secondary" 
            onClick={onCancel}
          >
            Cancel
          </button>
          <button 
            className="btn btn-fill-danger" 
            onClick={onConfirm}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteMemberModal;
