import React, { useMemo, useState } from 'react';

export const DefaultColumnFilter = ({
    column: { filterValue, setFilter },
}) => {
    return (
        <input
            className="table-filter"
            value={filterValue || ''}
            onChange={e => setFilter(e.target.value || undefined)}
            placeholder="Filter..."
        />
    );
};

export const SelectColumnFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
}) => {
    const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach(row => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    return (
        <select
            className="table-select-filter"
            value={filterValue}
            onChange={e => setFilter(e.target.value || undefined)}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
};

export const DateRangeColumnFilter = ({
    column: { filterValue = [], setFilter }
}) => {
    const [startDate, setStartDate] = useState(filterValue[0] || '');
    const [endDate, setEndDate] = useState(filterValue[1] || '');

    const handleStartDateChange = (e) => {
        const newStartDate = e.target.value;
        setStartDate(newStartDate);
        const newFilterValue = [newStartDate, endDate];
        setFilter(newStartDate || endDate ? newFilterValue : undefined);
    };

    const handleEndDateChange = (e) => {
        const newEndDate = e.target.value;
        setEndDate(newEndDate);
        const newFilterValue = [startDate, newEndDate];
        setFilter(startDate || newEndDate ? newFilterValue : undefined);
    };

    return (
        <div className="date-range-filter">
            <input
                type="date"
                value={startDate}
                onChange={handleStartDateChange}
                placeholder="From"
                className="date-filter-input"
            />
            <input
                type="date"
                value={endDate}
                onChange={handleEndDateChange}
                placeholder="To"
                className="date-filter-input"
            />
        </div>
    );
}; 