import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import ActionsDropdownMenu from '../ActionsDropdownMenu/ActionsDropdownMenu';
import DeleteArticleModal from '../Modals/DeleteArticleModal';
import './ArticleTopBar.css';

const ArticleTopBar = ({ backUrl, title, articleId, userRole }) => {
    const navigate = useNavigate();
    const [showDropdown, setShowDropdown] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);
    const isViewer = userRole === 'viewer';

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && 
                buttonRef.current && 
                !dropdownRef.current.contains(event.target) && 
                !buttonRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleBack = () => {
        navigate(backUrl);
    };

    return (
        <>
            <div className="top-bar">
                <div className="title-container">
                    <button onClick={handleBack} className="back-button">
                        <ChevronLeft size={24} />
                    </button>
                    <span className="title">{title}</span>
                </div>
                {!isViewer && (
                    <div style={{ position: 'relative' }}>
                        <button 
                            ref={buttonRef}
                            className="btn btn-outline-primary"
                            onClick={() => setShowDropdown(!showDropdown)}
                        >
                            Actions
                        </button>
                        {showDropdown && (
                            <div ref={dropdownRef}>
                                <ActionsDropdownMenu 
                                    onClose={() => setShowDropdown(false)}
                                    onDelete={() => setShowDeleteModal(true)}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
            {!isViewer && (
                <DeleteArticleModal 
                    showModal={showDeleteModal}
                    closeModal={() => setShowDeleteModal(false)}
                    articleId={articleId}
                />
            )}
        </>
    );
};

export default ArticleTopBar;