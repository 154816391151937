import React, { useContext, useEffect } from 'react';
import UserContext from '../context/UserContext';
import NavBar from './Navbar/NavBar';
import Header from './Headers/Header';
import InstallTheExtension from './Banners/InstallTheExtension/InstallTheExtension';
import './Home.css';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const { userData, loading, fetchUserData } = useContext(UserContext);
    const navigate = useNavigate();

    // Efecto para asegurar que tenemos los datos más recientes
    useEffect(() => {
        const verifyUserStatus = async () => {
            if (!loading) {
                const freshData = await fetchUserData(true);
                if (!freshData?.isEmailVerified) {
                    navigate('/email-validation', { replace: true });
                }
            }
        };

        verifyUserStatus();
    }, [loading, fetchUserData, navigate]);

    if (loading) {
        return (
            <div className="container-horizontal">
                <div className="main-content">
                    <div className="loading-state">Loading...</div>
                </div>
            </div>
        );
    }

    if (!userData) {
        navigate('/login', { replace: true });
        return null;
    }

    if (!userData.isEmailVerified) {
        navigate('/email-validation', { replace: true });
        return null;
    }

    return (
        <div className="container-horizontal">
            <NavBar />
            <div className="main-content">
                <Header 
                    title={`Welcome ${userData.name}!`}
                    description="Stop writing, start recording. Create knowledgebase articles faster than ever!"
                    actions={{
                        showCounter: false,
                        showButton: false
                    }}
                />
                <div className="home-content">
                    <div className="get-started-container">
                        <h2>Get started</h2>
                        <InstallTheExtension />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;


