import React, { useContext } from 'react';
import './PlansStats.css';
import { WorkspaceContext } from '../../../../context/WorkspaceContext';

const PlansStats = ({ currentPlan, nextInvoiceDate, invoiceTotal, planStatus }) => {
    const { workspaceMembers } = useContext(WorkspaceContext);
    
    const getBillableSeats = () => {
        return workspaceMembers.filter(member => 
            ['admin', 'editor'].includes(member.role) && 
            member.status === 'active'
        ).length;
    };

    const calculateProjectedTotal = () => {
        if (currentPlan === 'starter') return 0;
        const billableSeats = getBillableSeats();
        const pricePerSeat = currentPlan === 'professional' ? 20 : 35;
        return billableSeats * pricePerSeat;
    };

    const formatDate = (dateString) => {
        return dateString === "No invoice scheduled" 
            ? dateString 
            : dateString.split('T')[0];
    };

    return (
        <div className="plans-stats-container">
            <div className="stats-frame">
                <div className="stat-item">
                    <span className="stat-label">Current Plan</span>
                    <span className="stat-value">{currentPlan}</span>
                </div>

                <div className="stat-item">
                    <span className="stat-label">Next invoice issue date</span>
                    <span className="stat-value">{formatDate(nextInvoiceDate)}</span>
                </div>

                <div className="stat-item">
                    <span className="stat-label">Projected subtotal</span>
                    <span className="stat-value">${calculateProjectedTotal()}</span>
                </div>

                <div className="stat-item">
                    <span className="stat-label">Plan status</span>
                    <span className="stat-value">{planStatus}</span>
                </div>
            </div>
        </div>
    );
};

export default PlansStats;
