import React, { useState } from 'react';
import GeneratedContent from '../GenerateContent/GeneratedContent';
import EditableContent from '../EditableContent/EditableContent';
import EditableLanguage from '../EditableContent/EditableLanguage';
import ViewerContent from '../ViewContent/ViewerContent';
import './Tabs.css';

const Tabs = ({ articleId, userRole, languages }) => {
  const [activeTab, setActiveTab] = useState('editable');
  const isViewer = userRole === 'viewer';

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const renderContent = () => {
    if (activeTab === 'generated') {
      return <GeneratedContent articleId={articleId} />;
    }

    if (isViewer) {
      return <ViewerContent 
        articleId={articleId} 
        language={activeTab === 'editable' ? null : activeTab}
      />;
    }

    if (activeTab === 'editable') {
      return <EditableContent articleId={articleId} />;
    }

    return <EditableLanguage articleId={articleId} language={activeTab} />;
  };

  return (
    <div className="tabs-container">
      <div className="tabs">
        <button
          className={activeTab === 'generated' ? 'active' : ''}
          onClick={() => handleTabChange('generated')}
        >
          Generated Content
        </button>
        <button
          className={activeTab === 'editable' ? 'active' : ''}
          onClick={() => handleTabChange('editable')}
        >
          {isViewer ? 'Content' : 'Editable Content'}
        </button>
        {languages && languages.map((lang) => (
          <button
            key={lang.value}
            className={activeTab === lang.value ? 'active' : ''}
            onClick={() => handleTabChange(lang.value)}
          >
            {lang.label}
          </button>
        ))}
      </div>

      <div className="tab-content">
        {renderContent()}
      </div>
    </div>
  );
};

export default Tabs;
