import React from 'react';
import { ChevronDown, X } from 'lucide-react';
import '../../../styles/Modal.css';
import '../../../styles/Button.css';
import '../../../styles/Input.css';
import './FiltersModal.css';

const FiltersModal = ({ isOpen, onClose, headerGroups }) => {
    if (!isOpen) return null;

    return (
        <div className="modal">
            <div className="modal-content">
                <div className="filters-modal-header">
                    <h2 className="filters-modal-title">Filters</h2>
                    <button 
                        className="btn btn-flat-secondary"
                        onClick={onClose}
                    >
                        <X />
                    </button>
                    
                </div>
                <div className="filters-modal-body">
                    {headerGroups[0].headers.map(column => {
                        if (!column.canFilter) return null;
                        
                        if (['selection', 'actions'].includes(column.id)) return null;

                        // Special handling for Title column
                        if (column.id === 'title') {
                            return (
                                <div key={column.id} className="filter-row">
                                    <div className="filter-label">
                                        <label className="filter-label-text">{column.render('Header')}</label>
                                    </div>
                                    <div className="filter-select-wrapper">
                                        <input
                                            type="text"
                                            value={column.filterValue || ''}
                                            onChange={e => column.setFilter(e.target.value)}
                                            placeholder="Search by title"
                                            className="input"
                                        />
                                    </div>
                                </div>
                            );
                        }

                        // Special handling for Created At (date range)
                        if (column.id === 'createdAt') {
                            return (
                                <div key={column.id} className="filter-row">
                                    <div className="filter-label">
                                        <label className="filter-label-text">{column.render('Header')}</label>
                                    </div>
                                    <div className="filter-input-container">
                                        {column.render('Filter')}
                                    </div>
                                </div>
                            );
                        }

                        // For all other columns
                        return (
                            <div key={column.id} className="filter-row">
                                <div className="filter-label">
                                    <label className="filter-label-text">{column.render('Header')}</label>
                                </div>
                                <div className="filter-select-wrapper">
                                    {column.render('Filter')}
                                    <ChevronDown className="filter-icon" />
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div className="modal-actions">
                    <button
                        className="btn btn-flat-primary"
                        onClick={() => {
                            // Reset all filters
                            headerGroups[0].headers.forEach(column => {
                                if (column.canFilter) {
                                    column.setFilter(undefined);
                                }
                            });
                            onClose();
                        }}
                    >
                        Reset filters
                    </button>
                    <button
                        className="btn btn-fill-primary"
                        onClick={onClose}
                    >
                        Apply filters
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FiltersModal; 