import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../../styles/Modal.css';
import '../../../../styles/Button.css';

const DeleteArticleModal = ({ showModal, closeModal, articleId }) => {
    const navigate = useNavigate();

    const handleDeleteArticle = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/articles/${articleId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include'
            });
            
            if (response.ok) {
                closeModal();
                navigate('/articles');
            } else {
                throw new Error('Failed to delete article');
            }
        } catch (error) {
            alert('Error deleting article');
        }
    };

    if (!showModal) return null;

    return (
        <div className="modal">
            <div className="modal-content">
                <h2>Confirm Delete</h2>
                <p>
                    Are you sure you want to delete this article? <br /> 
                    This action cannot be undone.
                </p>
                
                <div className="modal-actions">
                    <button 
                        className="btn btn-outline-secondary" 
                        onClick={closeModal}
                    >   
                        Cancel
                    </button>
                    <button 
                        className="btn btn-fill-danger" 
                        onClick={handleDeleteArticle}
                    >
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeleteArticleModal;
