import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import './ViewerContent.css';

const ViewerContent = ({ articleId, language = null }) => {
  const [content, setContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const endpoint = language 
          ? `/api/articles/${articleId}/editableTranslations/${language}`
          : `/api/articles/${articleId}`;

        const response = await fetch(endpoint, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        });

        if (response.ok) {
          const data = await response.json();
          let contentToShow;
          
          if (language) {
            contentToShow = data.content;
          } else {
            contentToShow = data.article.editableContent?.content || data.article.content;
          }

          setContent(DOMPurify.sanitize(contentToShow || ''));
        } else {
          console.error('Error fetching content');
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchContent();
  }, [articleId, language]);

  if (isLoading) {
    return <div className="viewer-loading">Cargando contenido...</div>;
  }

  return (
    <div className="viewer-content">
      <div 
        className="content-html"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};

export default ViewerContent;
