import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../../../context/UserContext';
import useValidationEmail from '../../../Emails/ValidationEmail/ValidationEmail';
import './EmailValidation.css';
import '../../Signup.css';

const EmailValidation = () => {
    const [verificationCode, setVerificationCode] = useState('');
    const [lastEmailSent, setLastEmailSent] = useState(() => {
        const stored = localStorage.getItem('lastEmailValidationSent');
        return stored ? parseInt(stored) : 0;
    });
    const [emailSendingError, setEmailSendingError] = useState(null);
    const { userData, fetchUserData } = useContext(UserContext);
    const navigate = useNavigate();
    const { isLoading, sendValidationEmail } = useValidationEmail();
    const [isInitialEmail, setIsInitialEmail] = useState(true);
    const [hasInitialEmailBeenSent, setHasInitialEmailBeenSent] = useState(() => {
        return localStorage.getItem('initialEmailSent') === 'true';
    });
    const [isUserDataReady, setIsUserDataReady] = useState(false);
    const [isSendingEmail, setIsSendingEmail] = useState(false);

    useEffect(() => {
        let isMounted = true;

        const initializeUserData = async () => {
            try {
                if (!userData) {
                    await fetchUserData();
                }
                if (isMounted) {
                    setIsUserDataReady(true);
                }
            } catch (error) {
                console.error('Error inicializando datos:', error);
                if (isMounted) {
                    setEmailSendingError('Error al cargar datos del usuario');
                }
            }
        };

        initializeUserData();

        return () => {
            isMounted = false;
        };
    }, [userData, fetchUserData]);

    const handleInitialEmailSend = useCallback(async () => {
        if (isSendingEmail) {
            console.log('Ya hay un envío en proceso...');
            return;
        }

        try {
            setIsSendingEmail(true);
            const now = Date.now();
            const fiveMinutes = 5 * 60 * 1000;

            if (!isInitialEmail && lastEmailSent && (now - lastEmailSent < fiveMinutes)) {
                console.log('Email enviado recientemente, esperando cooldown...');
                return;
            }

            setEmailSendingError(null);
            const newVerificationCode = Math.floor(100000 + Math.random() * 900000).toString();
            
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No se encontró el token de autenticación');
            }

            console.log('Guardando código de verificación en:', `${process.env.REACT_APP_API_URL}/auth/save-verification-code`);
            
            const saveResponse = await fetch(`${process.env.REACT_APP_API_URL}/auth/save-verification-code`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ verificationCode: newVerificationCode })
            });

            console.log('Status de la respuesta para guardar código:', saveResponse.status);

            if (!saveResponse.ok) {
                const errorData = await saveResponse.json().catch(() => ({}));
                throw new Error(errorData.message || 'Error al guardar el código de verificación');
            }

            const result = await sendValidationEmail(newVerificationCode);
            if (!result.success) {
                throw new Error(result.message);
            }

            setLastEmailSent(result.timestamp);
            localStorage.setItem('lastEmailValidationSent', result.timestamp.toString());
            setIsInitialEmail(false);
            setHasInitialEmailBeenSent(true);
            localStorage.setItem('initialEmailSent', 'true');

        } catch (error) {
            console.error('Error en el envío inicial:', error);
            setEmailSendingError(error.message);
        } finally {
            setIsSendingEmail(false);
        }
    }, [isInitialEmail, lastEmailSent, sendValidationEmail, isSendingEmail]);

    useEffect(() => {
        let isMounted = true;

        const handleEmailLogic = async () => {
            if (!isUserDataReady) return;

            if (!userData) {
                if (isMounted) navigate('/login', { replace: true });
                return;
            }

            if (userData.isEmailVerified) {
                if (isMounted) navigate('/home', { replace: true });
                return;
            }

            if (!hasInitialEmailBeenSent && !isSendingEmail && isMounted) {
                await handleInitialEmailSend();
            }
        };

        handleEmailLogic();

        return () => {
            isMounted = false;
        };
    }, [
        userData, 
        navigate, 
        handleInitialEmailSend, 
        hasInitialEmailBeenSent, 
        isUserDataReady,
        isSendingEmail
    ]);

    const handleVerifyEmail = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No se encontró el token de autenticación');
            }

            console.log('Verificando código en:', `${process.env.REACT_APP_API_URL}/auth/verify-code`);
            
            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/verify-code`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ verificationCode })
            });

            console.log('Status de la respuesta para verificación:', response.status);

            let data;
            try {
                data = await response.json();
            } catch (jsonError) {
                console.error('Error al parsear JSON:', jsonError);
                throw new Error('Error en el formato de respuesta del servidor');
            }
            
            if (response.ok && data.token) {
                localStorage.setItem('token', data.token);
                
                await fetchUserData(true);
                
                const updatedUserData = await fetchUserData();
                
                if (!updatedUserData || !updatedUserData.isEmailVerified) {
                    throw new Error('La verificación del email no se completó correctamente');
                }

                navigate('/home', { replace: true });
            } else {
                throw new Error(data.message || 'Código de verificación inválido');
            }
        } catch (error) {
            console.error('Error en la verificación:', error);
            alert(error.message || 'Error al verificar el código');
        }
    };

    const handleCodeChange = (e) => {
        const value = e.target.value.replace(/\D/g, '').slice(0, 6);
        setVerificationCode(value);
    };

    return (
        <div className="signup-container">
            <div className="signup-form">
                <div className="email-validation-header">
                    <h2 className="signup-title">Check your email</h2>
                    {emailSendingError ? (
                        <div className="error-message">
                            {emailSendingError}
                            <button 
                                className="btn btn-flat-primary"
                                onClick={handleInitialEmailSend}
                                disabled={isLoading}
                            >
                                Try again
                            </button>
                        </div>
                    ) : (
                        <>
                            <p className="signup-description">
                                We've sent a verification code to your email. Please enter the 6-digit code below to verify your account.
                            </p>
                            <p className="email-sent-to">
                                Email sent to: <span>{userData?.email}</span>
                            </p>
                        </>
                    )}
                </div>
                
                <div className="verification-code-input">
                    <input
                        type="text"
                        value={verificationCode}
                        onChange={handleCodeChange}
                        placeholder="Enter 6-digit code"
                        maxLength="6"
                        className="input"
                    />
                </div>
                
                <div className="verification-actions">
                    <button 
                        className="btn btn-fill-primary"
                        onClick={handleVerifyEmail}
                        disabled={verificationCode.length !== 6}
                    >
                        Verify
                    </button>
                <div className="signup-actions">
                    <p className="signup-actions-text">Didn't receive the code?</p>
                    <button 
                        className="btn btn-flat-primary"
                        onClick={handleInitialEmailSend}
                        disabled={isLoading || (!isInitialEmail && lastEmailSent && (Date.now() - lastEmailSent < 5 * 60 * 1000))}>
                        Resend code
                    </button>
                </div>
                </div>
            </div>
        </div>
    );
};

export default EmailValidation;
