import React, { useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { WorkspaceContext } from '../../../context/WorkspaceContext';
import Header from '../../Headers/Header';
import InviteMembersModal from './Components/InviteMembersModal';
import DeleteMemberModal from './Components/DeleteMemberModal';
import MemberInfoModal from './Components/MembersModals/MemberInfoModal';
import './Members.css';

const Members = ({ userRole }) => {
  const { workspaceData, workspaceMembers, fetchWorkspaceData } = useContext(WorkspaceContext);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const canManageMembers = userRole === 'admin';

  const getAvatarUrl = (member) => {
    if (!member.avatar) return null;
    
    return member.avatar.startsWith('http') 
      ? member.avatar 
      : member.avatar.startsWith('/uploads')
        ? `${window.location.origin}${member.avatar}`
        : `${window.location.origin}/${member.avatar}`;
  };

  const getMemberStatus = (member) => {
    if (member.status === 'pending') {
      return (
        <span className="status-badge pending">
          Pending Invitation
        </span>
      );
    }
    return null;
  };

  const getRoleBadge = (role) => (
    <span className={`role-badge ${role}`}>
      {role}
    </span>
  );

  const getMemberActionButton = (member) => {
    if (!canManageMembers) return null;

    if (member.status === 'pending') {
      return (
        <button 
          className="btn btn-flat-secondary"
          onClick={(e) => {
            e.stopPropagation();
            setMemberToDelete(member);
          }}
        >
          Cancel Invitation
        </button>
      );
    }
    
    return (
      <button 
        className="btn btn-flat-danger"
        onClick={(e) => {
          e.stopPropagation();
          setMemberToDelete(member);
        }}
      >
        Remove
      </button>
    );
  };

  const handleRemoveMember = async (memberId) => {
    if (!canManageMembers) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/workspace/members/${memberId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        setMemberToDelete(null);
        await fetchWorkspaceData();
      }
    } catch (err) {
      console.error('Error removing member:', err);
    }
  };

  const handleRoleChange = async (memberId, newRole) => {
    try {
      setIsLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/workspace/members/${memberId}/role`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ newRole })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Error al actualizar el rol');
      }

      const data = await response.json();
      
      // Mostrar mensaje de éxito
      setMessage({ type: 'success', text: data.message });
      setTimeout(() => setMessage(null), 3000);

      // Solo actualizar los datos del workspace si hay cambio en la facturación
      const member = workspaceMembers.find(m => m._id === memberId);
      const oldRole = member?.role;
      const isOldRoleBillable = ['admin', 'editor'].includes(oldRole);
      const isNewRoleBillable = ['admin', 'editor'].includes(newRole);

      if (isOldRoleBillable !== isNewRoleBillable) {
        await fetchWorkspaceData(true);
      }

    } catch (error) {
      console.error('Error al actualizar el rol:', error);
      setMessage({ type: 'error', text: error.message });
      setTimeout(() => setMessage(null), 3000);
    } finally {
      setIsLoading(false);
    }
  };

  if (!workspaceData) {
    return <div>Loading workspace data...</div>;
  }

  return (
    <div className="members-container">
      <Helmet>
        <title>Members | KnowHub</title>
      </Helmet>
      <Header 
        title="Workspace Members"
        description="Manage the members of your workspace and their roles"
        actions={{
          showCounter: true,
          count: workspaceMembers?.length || 0,
          countLabel: "members",
          showButton: canManageMembers,
          buttonText: "Invite Member",
          buttonAction: () => setShowInviteModal(true)
        }}
      />

      <div className="members-list">
        {!canManageMembers && (
          <div className="settings-info-message">
            Only administrators can manage workspace members
          </div>
        )}
        {message && (
          <div className={`alert alert-${message.type}`}>
            {message.text}
          </div>
        )}
        {isLoading && (
          <div className="loading-overlay">
            <div className="spinner"></div>
            <p>Actualizando rol...</p>
          </div>
        )}
        {Array.isArray(workspaceMembers) && workspaceMembers.map((member) => (
          <div 
            key={member._id} 
            className="member-item"
            onClick={() => setSelectedMember(member)}
          >
            <div className="member-info">
              {member.avatar ? (
                <img 
                  src={getAvatarUrl(member)}
                  alt={`Avatar de ${member.name || 'usuario'}`}
                  className="member-avatar"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '';
                    e.target.style.display = 'none';
                    const placeholder = e.target.parentElement.querySelector('.avatar-placeholder');
                    if (placeholder) {
                      placeholder.style.display = 'flex';
                    }
                  }}
                />
              ) : null}
              <div 
                className="avatar-placeholder"
                style={{ display: member.avatar ? 'none' : 'flex' }}
              >
                {(member.name || member.email || '').charAt(0).toUpperCase()}
              </div>
              <div className="member-details">
                {member.name && <p className="member-name">{member.name}</p>}
                <p className="member-email">{member.email}</p>
              </div>
            </div>
            <div className="member-status">
              {getMemberStatus(member)}
              {getRoleBadge(member.role)}
            </div>
            <div className="member-actions">
              {getMemberActionButton(member)}
            </div>
          </div>
        ))}
      </div>

      {canManageMembers && memberToDelete && (
        <DeleteMemberModal
          member={memberToDelete}
          onConfirm={() => handleRemoveMember(memberToDelete._id)}
          onCancel={() => setMemberToDelete(null)}
        />
      )}

      {canManageMembers && (
        <InviteMembersModal
          showModal={showInviteModal}
          closeModal={() => setShowInviteModal(false)}
        />
      )}

      {selectedMember && (
        <MemberInfoModal
          member={selectedMember}
          onClose={() => setSelectedMember(null)}
          onUpdate={fetchWorkspaceData}
          isAdmin={canManageMembers}
          onRoleChange={handleRoleChange}
        />
      )}
    </div>
  );
};

export default Members;