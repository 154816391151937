import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { User, LogOut } from 'lucide-react';
import UserContext from '../../../context/UserContext';
import { WorkspaceContext } from '../../../context/WorkspaceContext';
import '../../../styles/DropdownMenu.css';
import './UserDropdown.css';

const UserDropdown = ({ onClose }) => {
    const navigate = useNavigate();
    const { fetchUserData } = useContext(UserContext);
    const { clearWorkspaceData } = useContext(WorkspaceContext);

    const handleLogout = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/logout`, {
                method: 'POST',
                credentials: 'include'
            });
            
            if (response.ok) {
                localStorage.removeItem('token');
                await fetchUserData();
                clearWorkspaceData();
                window.location.href = '/';
            } else {
                throw new Error('Logout failed');
            }
        } catch (error) {
            alert('An error occurred while logging out.');
        }
    };

    return (
        <div className="dropdown user-dropdown">
            <button 
                className="dropdown-item"
                onClick={() => {
                    navigate('/user');
                    onClose();
                }}
            >
                <User size={20} />
                <span>Account and preferences</span>
            </button>
            <button 
                className="dropdown-item"
                onClick={handleLogout}
            >
                <LogOut size={20} />
                <span>Logout</span>
            </button>
        </div>
    );
};

export default UserDropdown;
