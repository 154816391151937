import React from 'react';
import { Chrome } from 'lucide-react';
import './InstallTheExtension.css';

const InstallTheExtension = () => {
  return (
    <div className="install-extension">
      <div className="install-extension__content">
        <div className="install-extension__icon">
          <Chrome size={48} strokeWidth={2} />
        </div>
        
        <div className="install-extension__text-content">
          <h2 className="install-extension__title">Install the Chrome extension</h2>
          <p className="install-extension__description">
            Quick and free setup to start creating content effortlessly.
          </p>
          <button className="btn btn-fill-primary">
            Install extension
          </button>
        </div>
      </div>

      <div className="install-extension__features">
        <div className="install-extension__feature">
          <span className="feature-check">✓</span>
          <span>Free</span>
        </div>
        <div className="install-extension__feature">
          <span className="feature-check">✓</span>
          <span>Record videos effortlessly</span>
        </div>
        <div className="install-extension__feature">
          <span className="feature-check">✓</span>
          <span>Record in your favorite language</span>
        </div>
        <div className="install-extension__feature">
          <span className="feature-check">✓</span>
          <span>Automatically generate transcripts</span>
        </div>
        <div className="install-extension__feature">
          <span className="feature-check">✓</span>
          <span>Track interactions to generate articles</span>
        </div>
        <div className="install-extension__feature">
          <span className="feature-check">✓</span>
          <span>Create knowledge base articles seamlessly</span>
        </div>
      </div>
    </div>
  );
};

export default InstallTheExtension;
