import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { WorkspaceContext } from '../../../context/WorkspaceContext';
import './UpgradeBannerArticles.css';

const UpgradeBanner = ({ currentCount, articleLimit }) => {
    const navigate = useNavigate();
    const { workspaceData } = useContext(WorkspaceContext);

    const handleUpgradeClick = () => {
        navigate('/settings/plan-and-billing');
    };

    // Si no hay datos del workspace o el plan no es starter, no mostramos nada
    if (!workspaceData || workspaceData.plan !== 'starter') {
        return null;
    }

    return (
        <header className="banner-header">
            <div>
                <div className="banner-counter">
                    <span>{currentCount}</span>
                    <span>/</span>
                    <span>{articleLimit === 'Unlimited' ? '∞' : articleLimit}</span>
                    <span>articles</span>
                </div>
                <div className="banner-available">
                    {articleLimit === 'Unlimited' 
                        ? 'Sin límite de artículos' 
                        : `${articleLimit - currentCount} artículos disponibles`}
                </div>
            </div>
            <button 
                className="btn btn-fill-primary"
                onClick={handleUpgradeClick}
            >
                Upgrade
            </button>
        </header>
    );
};

export default UpgradeBanner;