import React, { useState } from 'react';
import '../../../../../styles/Modal.css';
import '../../Members.css';

const MemberInfoModal = ({ member, onClose, onUpdate, isAdmin, onRoleChange }) => {
    const [selectedRole, setSelectedRole] = useState(member.role);
    const [isUpdating, setIsUpdating] = useState(false);

    const getAvatarUrl = (member) => {
        if (!member.avatar) return null;
        return member.avatar.startsWith('http') 
            ? member.avatar 
            : member.avatar.startsWith('/uploads')
                ? `${window.location.origin}${member.avatar}`
                : `${window.location.origin}/${member.avatar}`;
    };

    const handleSaveChanges = async () => {
        if (isUpdating) return; // Prevenir múltiples clicks
        
        try {
            setIsUpdating(true);
            await onRoleChange(member._id, selectedRole);
        } catch (error) {
            console.error('Error updating member role:', error);
            // No cerramos el modal si hay error
        } finally {
            setIsUpdating(false);
            onClose(); // Solo cerramos el modal después de todo el proceso
        }
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <h2>Member Information</h2>
                <div className="member-info">
                    {member.avatar ? (
                        <img 
                            src={getAvatarUrl(member)}
                            alt={`Avatar de ${member.name || 'usuario'}`}
                            className="member-avatar"
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = '';
                                e.target.style.display = 'none';
                                const placeholder = e.target.parentElement.querySelector('.avatar-placeholder');
                                if (placeholder) {
                                    placeholder.style.display = 'flex';
                                }
                            }}
                        />
                    ) : null}
                    <div 
                        className="avatar-placeholder"
                        style={{ display: member.avatar ? 'none' : 'flex' }}
                    >
                        {(member.name || member.email || '').charAt(0).toUpperCase()}
                    </div>
                    <div className="member-details">
                        {member.name && <p className="member-name" style={{ margin: 0 }}>{member.name}</p>}
                        <p className="member-email" style={{ margin: 0 }}>{member.email}</p>
                    </div>
                </div>

                <div className="input-group">
                    <label htmlFor="role">Role</label>
                    <select
                        id="role"
                        className="input"
                        value={selectedRole}
                        onChange={(e) => setSelectedRole(e.target.value)}
                        disabled={!isAdmin || member.status === 'pending' || isUpdating}
                    >
                        <option value="viewer">Viewer</option>
                        <option value="editor">Editor</option>
                        <option value="admin">Admin</option>
                    </select>
                </div>

                <div className="modal-actions">
                    <button 
                        className="btn btn-flat-secondary"
                        onClick={onClose}
                        disabled={isUpdating}
                    >
                        Cancel
                    </button>
                    <button 
                        className="btn btn-fill-primary"
                        onClick={handleSaveChanges}
                        disabled={!isAdmin || member.status === 'pending' || isUpdating || selectedRole === member.role}
                    >
                        Save Changes
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MemberInfoModal;
