import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './Signup.css';
import '../../styles/Button.css';
import '../../styles/Input.css';
import { GoogleLogin } from '@react-oauth/google';

const Signup = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleSignup = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            setErrorMessage('');
            localStorage.clear();
            sessionStorage.clear();

            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ 
                    email, 
                    name, 
                    password,
                    role: 'admin'
                }),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Error en el registro');
            }

            if (data.userId && data.token) {
                console.log('Usuario creado exitosamente:', data.userId);
                sessionStorage.setItem('userId', data.userId);
                localStorage.setItem('token', data.token);
                navigate('/SignupWorkspace');
            } else {
                throw new Error(data.message || 'Respuesta inesperada del servidor');
            }
        } catch (error) {
            console.error('Error durante el registro:', error);
            setErrorMessage(error.message || 'Ocurrió un error. Por favor intenta de nuevo.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleGoogleSuccess = async (credentialResponse) => {
        try {
            setIsLoading(true);
            setErrorMessage('');

            if (!credentialResponse?.credential) {
                throw new Error('No se recibieron credenciales de Google');
            }

            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/google-signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ 
                    credential: credentialResponse.credential 
                }),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Error en el registro con Google');
            }

            if (data.userId && data.token) {
                sessionStorage.setItem('userId', data.userId);
                localStorage.setItem('token', data.token);
                navigate('/SignupWorkspace');
            } else {
                throw new Error('No se recibió token del servidor');
            }
        } catch (error) {
            console.error('Error durante el registro con Google:', error);
            setErrorMessage(error.message || 'Ocurrió un error. Por favor intenta de nuevo.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="signup-container">
            <Helmet>
                <title>Sign Up | KnowHub</title>
            </Helmet>
            <form className="signup-form" onSubmit={handleSignup}>
                <h2 className="signup-title">Create your account</h2>
                
                {errorMessage && (
                    <div className="error-message">
                        {errorMessage}
                    </div>
                )}

                <div className="input-group">
                    <label className="input-group-label" htmlFor="signup-email">
                        Your email
                    </label>
                    <input
                        className="input"
                        type="email"
                        id="signup-email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        autoComplete="email"
                        placeholder="your@email.com"
                        disabled={isLoading}
                    />
                </div>

                <div className="input-group">
                    <label className="input-group-label" htmlFor="signup-name">
                        Your name aquí
                    </label>
                    <input
                        className="input"
                        type="text"
                        id="signup-name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        autoComplete="name"
                        placeholder="John Doe"
                        disabled={isLoading}
                    />
                </div>

                <div className="input-group">
                    <label className="input-group-label" htmlFor="signup-password">
                        Your password
                    </label>
                    <input
                        className="input"
                        type="password"
                        id="signup-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        autoComplete="new-password"
                        placeholder="********"
                        disabled={isLoading}
                    />
                </div>

                <button 
                    className={`btn btn-fill-primary ${isLoading ? 'loading' : ''}`}
                    type="submit"
                    disabled={isLoading}
                >
                    {isLoading ? 'Registrando...' : 'Sign Up'}
                </button>

                <div className="social-login-container">
                    <p className="social-login-text">Or</p>
                    <div className="social-login">
                        <GoogleLogin
                            onSuccess={handleGoogleSuccess}
                            onError={() => {
                                console.error('Login Failed');
                                setErrorMessage('Error al iniciar sesión con Google');
                            }}
                            useOneTap={false}
                            cookiePolicy={'single_host_origin'}
                            disabled={isLoading}
                        />
                    </div>
                </div>
                <div className="signup-actions">
                    <p className="signup-actions-text">Already have an account?</p>
                    <button 
                        className="btn btn-flat-primary" 
                        onClick={() => navigate('/login')}
                        disabled={isLoading}
                    >
                        Login
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Signup;
