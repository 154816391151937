import React, { useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../Headers/Header';
import '../../../styles/Input.css';
import '../../../styles/Button.css';
import './CompanySettings.css';
import { WorkspaceContext } from '../../../context/WorkspaceContext';

const CompanySettings = ({ userRole }) => {
  const { workspaceData, fetchWorkspaceData } = useContext(WorkspaceContext);
  const [workspaceName, setWorkspaceName] = useState('');
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState(null);

  const canEditWorkspace = userRole === 'admin';

  useEffect(() => {
    if (workspaceData?.name) {
      setWorkspaceName(workspaceData.name);
      setIsLoading(false);
    }
  }, [workspaceData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!canEditWorkspace) return;

    try {
      setIsSaving(true);
      setError(null);
      const token = localStorage.getItem('token');
      
      if (!token) {
        setError('No authentication token found');
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/workspace/name`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        credentials: 'include',
        body: JSON.stringify({ name: workspaceName.trim() }),
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || 'Error updating workspace name');
      }

      await fetchWorkspaceData();
      setMessage({ type: 'success', text: 'Workspace name updated successfully' });
      setTimeout(() => setMessage(null), 3000);

    } catch (error) {
      setError(error.message || 'Error updating workspace name');
      setWorkspaceName(workspaceData?.name || '');
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    setWorkspaceName(workspaceData?.name || '');
    setError(null);
  };

  const hasChanges = workspaceName !== workspaceData?.name;

  if (isLoading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Loading workspace settings...</p>
      </div>
    );
  }

  if (!workspaceData) {
    return (
      <div className="error-container">
        <p>Error: No workspace data available</p>
      </div>
    );
  }

  return (
    <div>
      <Helmet>
        <title>Workspace Settings | KnowHub </title>
      </Helmet>
      <Header 
        title="Workspace Settings"
        description="Here you can manage all workspace-related information"
        actions={{
          showCounter: false,  
          showButton: false, 
          count: "",
          countLabel: "",
          buttonText: ""
        }}
      />
      
      <div className="company-settings-container">
        <h2 className="company-settings-title">
          Workspace name
        </h2>
        
        <div className="input-group">
          <label>Workspace name</label>
          {message && (
            <div className={`alert alert-${message.type}`}>
              {message.text}
            </div>
          )}
          <form 
            onSubmit={handleSubmit} 
            className="company-settings-form"
          >
            <input
              type="text"
              value={workspaceName}
              onChange={(e) => setWorkspaceName(e.target.value)}
              className="input"
              placeholder="Enter workspace name"
              disabled={!canEditWorkspace || isSaving}
            />
            {error && <div className="company-settings-error">{error}</div>}
            {!canEditWorkspace && (
              <div className="company-settings-info">
                Only administrators can modify workspace settings
              </div>
            )}
            {canEditWorkspace && (
              <div className="company-settings-buttons">
                <button
                  type="submit"
                  className="btn btn-fill-primary"
                  disabled={!hasChanges || isSaving}
                >
                  {isSaving ? 'Saving...' : 'Save'}
                </button>
                <button
                  type="button"
                  onClick={handleCancel}
                  className="btn btn-flat-primary"
                  disabled={!hasChanges || isSaving}
                >
                  Cancel
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default CompanySettings;
