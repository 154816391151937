import React, { useContext, useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import { BookOpenText, Settings } from 'lucide-react';
import UserDropdown from './components/UserDropdown';
import './NavBar.css';

const NavBar = () => {
    const { userData } = useContext(UserContext);
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const avatarRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && 
                avatarRef.current && 
                !dropdownRef.current.contains(event.target) && 
                !avatarRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <nav className="navbar">
            <div className="nav-top">
                <NavLink to="/home" className="nav-logo">
                    <img src="/images/knowhub_logo.svg" alt="KnowHub Logo" />
                </NavLink>
                <div className="nav-buttons">
                    <NavLink to="/articles" className="nav-button">
                        <div className="nav-button-icon">
                            <BookOpenText size={24} />
                        </div>
                        <span>Articles</span>
                    </NavLink>
                    
                    <NavLink to="/settings" className="nav-button">
                        <div className="nav-button-icon">
                            <Settings size={24} />
                        </div>
                        <span>Settings</span>
                    </NavLink>
                </div>
            </div>

            <div className="nav-avatar-container" style={{ position: 'relative' }}>
                <button 
                    ref={avatarRef}
                    className="nav-avatar"
                    onClick={() => setShowDropdown(!showDropdown)}
                >
                    {userData?.avatar ? (
                        <img 
                            src={userData.avatar} 
                            alt="Avatar"
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.style.display = 'none';
                                const placeholder = e.target.nextElementSibling;
                                if (placeholder) {
                                    placeholder.style.display = 'flex';
                                }
                            }}
                        />
                    ) : null}
                    <div 
                        className="avatar-placeholder"
                        style={{
                            width: '40px',
                            height: '40px',
                            borderRadius: '50%',
                            backgroundColor: '#e0e0e0',
                            display: userData?.avatar ? 'none' : 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#666',
                            fontWeight: 'bold',
                            fontSize: '18px',
                            textTransform: 'uppercase'
                        }}
                    >
                        {(userData?.email || '').charAt(0)}
                    </div>
                </button>
                {showDropdown && (
                    <div ref={dropdownRef}>
                        <UserDropdown onClose={() => setShowDropdown(false)} />
                    </div>
                )}
            </div>
        </nav>
    );
};

export default NavBar;
