import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import SettingsSidebar from './SettingsSidebar';
import CompanySettings from './CompanySettings/CompanySettings';
import LocalizationSettings from './Localization/LocalizationSettings';
import PlanAndBilling from './PlansAndBillings/PlanAndBilling';
import NavBar from '../Navbar/NavBar';
import UserContext from '../../context/UserContext';
import Members from './Members/Members';
import './Settings.css';

const Settings = () => {
  const { userData, loading } = useContext(UserContext);
  const userRole = userData?.currentWorkspaceRole;

  if (loading) {
    return <div>Loading...</div>; // Mostrar un mensaje de carga mientras se obtienen los datos
  }

  if (!userData) {
    return <div>Error: Unable to load user data.</div>; // Manejar el caso de error
  }

  return (
    <div className="settings-container">
      <div className="container-horizontal">
        <NavBar />
        <div className="settings-layout">
          <SettingsSidebar userRole={userRole} />
          <div className="settings-content">
            <Routes>
              <Route path="/" element={<CompanySettings userRole={userRole} />} />
              <Route path="/localization" element={<LocalizationSettings userRole={userRole} />} />
              <Route path="/plan-and-billing" element={<PlanAndBilling userRole={userRole} />} />
              <Route path="/members" element={<Members userRole={userRole} />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
