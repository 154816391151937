import React, { createContext, useState, useCallback, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

export const WorkspaceContext = createContext();

export const WorkspaceProvider = ({ children }) => {
    const [workspaceData, setWorkspaceData] = useState(null);
    const [workspaceLanguages, setWorkspaceLanguages] = useState([]);
    const [workspaceMembers, setWorkspaceMembers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [lastFetch, setLastFetch] = useState(0);

    const getApiUrl = useCallback(() => {
        return process.env.REACT_APP_API_URL;
    }, []);

    const clearWorkspaceData = useCallback(() => {
        setWorkspaceData(null);
        setWorkspaceLanguages([]);
        setWorkspaceMembers([]);
        setLoading(false);
    }, []);

    const fetchWorkspaceData = useCallback(async (forceRefresh = false) => {
        const now = Date.now();
        const CACHE_DURATION = 2000;

        if (!forceRefresh && (now - lastFetch) < CACHE_DURATION) {
            return;
        }

        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                clearWorkspaceData();
                return;
            }

            const decodedToken = jwtDecode(token);
            if (!decodedToken.workspaceId) {
                clearWorkspaceData();
                return;
            }

            const apiUrl = getApiUrl();
            const [workspaceResponse, membersResponse] = await Promise.all([
                fetch(`${apiUrl}/workspace`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    credentials: 'include'
                }),
                fetch(`${apiUrl}/workspace/members`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    credentials: 'include'
                })
            ]);

            if (!workspaceResponse.ok || !membersResponse.ok) {
                throw new Error('Error fetching workspace data');
            }

            const [workspaceResult, membersResult] = await Promise.all([
                workspaceResponse.json(),
                membersResponse.json()
            ]);

            if (!workspaceResult.workspace) {
                throw new Error('No workspace data received');
            }

            setWorkspaceData(workspaceResult.workspace);
            setWorkspaceMembers(membersResult.members || []);
            setLastFetch(now);

            const languagesResponse = await fetch(`${apiUrl}/settings/languages`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            });

            if (languagesResponse.ok) {
                const languagesData = await languagesResponse.json();
                setWorkspaceLanguages(languagesData.languages || []);
            } else {
                throw new Error('Error fetching languages');
            }
        } catch (error) {
            clearWorkspaceData();
        } finally {
            setLoading(false);
        }
    }, [clearWorkspaceData, lastFetch, getApiUrl]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            fetchWorkspaceData();
        } else {
            setLoading(false);
        }
    }, [fetchWorkspaceData]);

    const value = {
        workspaceData,
        workspaceLanguages,
        workspaceMembers,
        loading,
        fetchWorkspaceData,
        clearWorkspaceData
    };

    return (
        <WorkspaceContext.Provider value={value}>
            {children}
        </WorkspaceContext.Provider>
    );
};
