import React from 'react';
import '../../../../styles/Modal.css';
import '../../../../styles/Button.css';
const ConfirmReviewLanguageModal = ({ showModal, closeModal, handleConfirm }) => {
    return (
        showModal && (
            <div className="modal">
                <div className="modal-content">
                    <h2>Do you want to mark this translation as reviewed?</h2>
                    <p>This action cannot be undone.</p>
                    <div className="modal-actions">
                        <button className="btn btn-outline-primary" onClick={closeModal}>Cancel</button>
                        <button className="btn btn-fill-primary" onClick={handleConfirm}>Accept</button>
                    </div>
                </div>
            </div>
        )
    );
};

export default ConfirmReviewLanguageModal;

