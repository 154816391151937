import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { WorkspaceContext } from '../../../context/WorkspaceContext';
import PlansCards from './components/PlansCards';
import Header from '../../../components/Headers/Header';
import PlansStats from './components/PlansStats';
import PlansAndBillingPaymentSummary from './components/PlansAndBillingPaymentSummary';
import { Helmet } from 'react-helmet';
import DowngradeConfirmationModal from './components/DowngradeConfirmationModal';

const PlanAndBilling = ({ userRole }) => {
    const { workspaceData, loading, fetchWorkspaceData } = useContext(WorkspaceContext);
    const [searchParams] = useSearchParams();
    const success = searchParams.get('success');
    const canManagePlans = userRole === 'admin';
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const navigate = useNavigate();
    const [showDowngradeModal, setShowDowngradeModal] = useState(false);
    const [pendingDowngrade, setPendingDowngrade] = useState(null);

    // Memoizar el cálculo de asientos facturables
    const billableSeats = useMemo(() => {
        if (!workspaceData?.members) return 0;
        return workspaceData.members.filter(member => 
            ['admin', 'editor'].includes(member.role) && 
            member.status === 'active'
        ).length;
    }, [workspaceData?.members]);

    useEffect(() => {
        let isMounted = true;

        const updateWorkspace = async () => {
            if (success === 'true' && !isUpdating && isMounted) {
                setIsUpdating(true);
                await fetchWorkspaceData(true);
                if (isMounted) {
                    setIsUpdating(false);
                    navigate('/settings/plan-and-billing', { replace: true });
                }
            }
        };
        
        updateWorkspace();

        return () => {
            isMounted = false;
        };
    }, [success, fetchWorkspaceData, navigate, isUpdating]);

    const handlePlanSelection = (plan) => {
        if (plan.name.toLowerCase() === 'starter') {
            setShowDowngradeModal(true);
            setPendingDowngrade(plan);
            return;
        }
        setSelectedPlan(plan);
    };

    const handleDowngradeConfirm = async () => {
        if (!pendingDowngrade) return;
        
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/stripe/create-checkout-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                credentials: 'include',
                body: JSON.stringify({
                    planId: pendingDowngrade.id,
                    planName: pendingDowngrade.name.toLowerCase()
                })
            });

            if (response.ok) {
                const { url } = await response.json();
                window.location.href = url;
            }
        } catch (error) {
            console.error('Error processing downgrade:', error);
        } finally {
            setShowDowngradeModal(false);
            setPendingDowngrade(null);
        }
    };

    if (loading) {
        return (
            <div className="loading-container">
                <div className="spinner"></div>
                <p>Loading plan and billing information...</p>
            </div>
        );
    }

    if (!workspaceData) {
        return (
            <div className="error-container">
                <p>Error: Unable to load workspace data.</p>
            </div>
        );
    }

    if (selectedPlan) {
        return (
            <PlansAndBillingPaymentSummary 
                selectedPlan={selectedPlan}
                onCancel={() => setSelectedPlan(null)}
            />
        );
    }

    return (
        <div className="plan-billing-container">
            <Helmet>
                <title>Plan & Billing | KnowHub</title>
            </Helmet>
            <Header 
                title="Plan and Billing"
                description="Manage your subscription and billing details"
                actions={{
                    showCounter: false,
                    count: workspaceData.plan,
                    countLabel: "Current Plan",
                    showButton: false,
                    buttonText: ""
                }}
            />

            {!canManagePlans && (
                <div className="settings-info-message" style={{ marginLeft: 'var(--spacing-500)', marginRight: 'var(--spacing-500)' }}>
                    Only administrators can manage workspace plans and billing
                </div>
            )}
            
            <PlansStats 
                currentPlan={workspaceData.plan}
                nextInvoiceDate={workspaceData.nextInvoiceDate || 'No invoice scheduled'}
                invoiceTotal={workspaceData.currentPlanPrice || 0}
                planStatus={workspaceData.subscriptionStatus || 'inactive'}
                billableSeats={billableSeats}
            />
            
            <div className="available-plans">
                <PlansCards 
                    canManagePlans={canManagePlans}
                    currentPlan={workspaceData.plan}
                    onSelectPlan={handlePlanSelection}
                    billableSeats={billableSeats}
                />
            </div>

            <DowngradeConfirmationModal 
                isOpen={showDowngradeModal}
                onClose={() => {
                    setShowDowngradeModal(false);
                    setPendingDowngrade(null);
                }}
                onConfirm={handleDowngradeConfirm}
            />
        </div>
    );
};

export default PlanAndBilling;
