import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Landing.css';
import '../../styles/Button.css';

const Landing = () => {
    const navigate = useNavigate();

    const navigateTo = (page) => {
        navigate(`/${page}`);
    };

    return (
        <div className="landing-container">
            <h1 className="landing-title">Welcome</h1>
            <div className="landing-buttons">
               
                <button 
                    className="btn btn-fill-primary"
                    onClick={() => navigateTo('signup')}
                >
                    Signup
                </button>
                <button 
                    className="btn btn-outline-primary"
                    onClick={() => navigateTo('login')}
                >
                    Login
                </button>
            </div>
        </div>
    );
};

export default Landing;
