import React, { useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import NavBar from '../../Navbar/NavBar';
import UserContext from '../../../context/UserContext';
import Header from '../../Headers/Header';
import '../../../styles/Input.css';
import '../../../styles/Button.css';
import './User.css';

const User = () => {
    const { userData, loading, fetchUserData } = useContext(UserContext);
    const [userName, setUserName] = useState(userData?.name || '');
    const [error, setError] = useState(null);
    // const [avatar, setAvatar] = useState(null);
    const [avatarPreview, setAvatarPreview] = useState(userData?.avatar || null);

    useEffect(() => {
        setUserName(userData?.name || '');
        setAvatarPreview(userData?.avatar || null);
    }, [userData]);

    const handleAvatarChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
        const maxSize = 5 * 1024 * 1024; // 5MB

        if (!validTypes.includes(file.type)) {
            setError('Please upload a valid image file (JPEG, PNG, or GIF)');
            return;
        }

        if (file.size > maxSize) {
            setError('File size should be less than 5MB');
            return;
        }

        setAvatarPreview(URL.createObjectURL(file));
        
        const formData = new FormData();
        formData.append('avatar', file);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/userdata/avatar`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                credentials: 'include',
                body: formData,
            });
            
            if (response.ok) {
                await fetchUserData();
                setError(null);
            } else {
                const data = await response.json();
                setError(data.message || 'Error al actualizar la foto de perfil');
                setAvatarPreview(userData?.avatar || null);
            }
        } catch (error) {
            setError('Error al actualizar la foto de perfil');
            setAvatarPreview(userData?.avatar || null);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/userdata/name`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                credentials: 'include',
                body: JSON.stringify({ name: userName }),
            });
            
            if (response.ok) {
                await fetchUserData();
                setError(null);
            } else {
                const data = await response.json();
                setError(data.message || 'Error al actualizar el nombre de usuario');
            }
        } catch (error) {
            setError('Error al actualizar el nombre de usuario');
        }
    };

    const handleCancel = () => {
        setUserName(userData?.name || '');
        setError(null);
    };

    const hasChanges = userName !== userData?.name;

    return (
        <div className="container-horizontal">
            <Helmet>
                <title>Personal Settings | KnowHub</title>
            </Helmet>
            <NavBar />
            <div className="user-settings-main-content">
                <div className="user-settings-content">
                    <Header 
                        title="Personal Settings"
                        description="Manage your personal settings here"
                        actions={{
                            showButton: false,
                            buttonText: "Logout",
                            buttonAction: null,
                            showCounter: false
                        }}
                    />
                    
                    <div className="user-settings-container">
                        <h2 className="user-settings-title">
                            Name and Photo
                        </h2>
                        <div className="inputs-group-user-block">
                            <div className="input-group">
                                <label>Profile Photo</label>
                                <div className="avatar-container">
                                    <div className="avatar-preview">
                                        {avatarPreview ? (
                                            <img 
                                                src={avatarPreview} 
                                                alt="Avatar preview" 
                                                className="avatar-image"
                                            />
                                        ) : (
                                            <div className="avatar-placeholder">
                                                {userData?.name?.charAt(0)?.toUpperCase() || '?'}
                                            </div>
                                        )}
                                    </div>
                                    <form className="avatar-form">
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={handleAvatarChange}
                                            className="avatar-input"
                                            disabled={loading}
                                            id="avatar-input"
                                            style={{ display: 'none' }}
                                        />
                                        <label 
                                            htmlFor="avatar-input" 
                                            className="btn btn-fill-secondary"
                                            style={{ 
                                                fontSize: 'var(--font-size-300)',
                                                padding: 'var(--spacing-200) var(--spacing-400)',
                                                lineHeight: 'normal'
                                            }}
                                        >
                                            Change Photo
                                        </label>
                                    </form>
                                </div>
                            </div>
                            <div className="input-group">
                                <label>Username</label>
                                <form onSubmit={handleSubmit} className="user-settings-form">
                                    <input
                                        type="text"
                                        value={userName}
                                        onChange={(e) => setUserName(e.target.value)}
                                        className="input"
                                        placeholder="Username"
                                        disabled={loading}
                                    />
                                    {error && <div className="user-settings-error">{error}</div>}
                                    <div className="user-settings-buttons">
                                        <button
                                            type="submit"
                                            className="btn btn-fill-primary"
                                            disabled={!hasChanges || loading}
                                        >
                                            Save
                                        </button>
                                        <button
                                            type="button"
                                            onClick={handleCancel}
                                            className="btn btn-flat-primary"
                                            disabled={!hasChanges || loading}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="input-group">
                            <label>Email</label>
                            <form className="user-settings-form">
                                <input type="text" value={userData?.email || ''} className="input" placeholder={userData?.email || ''} disabled />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default User;
