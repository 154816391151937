import React from 'react';
import '../../../styles/Modal.css';
import '../../../styles/Button.css';

const ModalDeleteArticle = ({ showModal, closeModal, handleDeleteArticle, selectedCount = 1 }) => {
    if (!showModal) return null;

    return (
        <div className="modal">
            <div className="modal-content">
                <h2>Confirmar Eliminación</h2>
                <p>
                    {selectedCount > 1 
                        ? `¿Estás seguro de que deseas eliminar ${selectedCount} artículos? Esta acción no se puede deshacer.`
                        : '¿Estás seguro de que deseas eliminar este artículo? Esta acción no se puede deshacer.'}
                </p>
                
                <div className="modal-actions">
                    <button 
                        className="btn btn-outline-secondary" 
                        onClick={closeModal}
                    >
                        Cancelar
                    </button>
                    <button 
                        className="btn btn-fill-danger" 
                        onClick={handleDeleteArticle}
                    >
                        Eliminar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ModalDeleteArticle;
