import { useState } from 'react';
import { jwtDecode } from 'jwt-decode';

const useValidationEmail = () => {
    const [isLoading, setIsLoading] = useState(false);

    const sendValidationEmail = async (verificationCode) => {
        setIsLoading(true);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No hay token disponible');
            }

            // Obtener los datos del usuario directamente del token
            const decodedToken = jwtDecode(token);
            const userData = {
                email: decodedToken.email,
                name: decodedToken.name
            };
            
            console.log('Datos obtenidos del token:', userData);

            if (!userData.email || !userData.name || !verificationCode) {
                console.error('Datos faltantes:', {
                    email: userData.email,
                    name: userData.name,
                    verificationCode
                });
                throw new Error('Faltan datos requeridos para el envío del email');
            }

            // Crear el objeto de email
            const emailData = {
                to: userData.email,
                from: 'no-reply@knowhub.app',
                subject: 'Your one time verification code - KnowHub',
                text: `Hi ${userData.name}, Your verification code is: ${verificationCode}`,
                html: `
                    <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px;">
                        <h1 style="color: #000000; font-size: 24px; margin-bottom: 32px;">KnowHub</h1>
                        <div style="margin-bottom: 32px;">
                            <p style="color: #000000; font-size: 16px; margin-bottom: 12px;">
                                Hi ${userData.name}, this is your one time verification code:
                            </p>
                            <div style="background-color: #FFFFFF; border: 1px solid #E4E4E4; border-radius: 8px; padding: 16px; text-align: center; margin: 12px 0;">
                                <span style="color: #131314; font-size: 24px; font-weight: bold;">${verificationCode}</span>
                            </div>
                            <p style="color: #131314; font-size: 14px;">
                                This code will only be active for the next 30 minutes. Once the code expires, you will need to request a new one.
                            </p>
                        </div>
                        <p style="color: #606669; font-size: 14px; margin-bottom: 32px;">
                            KnowHub is a tool to making knowledge base articles faster than ever!
                        </p>
                        <a href="https://knowhub.app" style="color: #703BEF; text-decoration: none; font-size: 14px;">
                            knowhub.app
                        </a>
                    </div>
                `,
                verificationCode
            };

            console.log('Enviando email a:', `${process.env.REACT_APP_API_URL}/send-email`);
            
            const response = await fetch(`${process.env.REACT_APP_API_URL}/send-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include',
                body: JSON.stringify(emailData)
            });

            console.log('Status de la respuesta del email:', response.status);
            
            let data;
            try {
                data = await response.json();
            } catch (jsonError) {
                console.error('Error al parsear JSON:', jsonError);
                throw new Error('Error en el formato de respuesta del servidor');
            }
            
            if (!response.ok || !data.success) {
                throw new Error(data.error || 'Error al enviar el email');
            }

            // Guardar el estado del email enviado
            localStorage.setItem('lastEmailSent', data.timestamp);
            localStorage.setItem('initialEmailSent', 'true');

            return { 
                success: true, 
                message: 'Email enviado correctamente',
                timestamp: data.timestamp
            };
        } catch (error) {
            console.error('Error en sendValidationEmail:', error);
            return { 
                success: false, 
                message: error.message || 'Error al enviar el email de verificación' 
            };
        } finally {
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        sendValidationEmail
    };
};

export default useValidationEmail;
