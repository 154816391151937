import React, { useState, useContext } from 'react';
import { WorkspaceContext } from '../../../../context/WorkspaceContext';
import useWorkspaceInvitationEmail from '../../../Emails/WorkspaceInvitationEmail/WorkspaceInvitationEmail';
import '../../../../styles/Modal.css';
import '../../../../styles/Button.css';
import '../../../../styles/Input.css';

const InviteMembersModal = ({ showModal, closeModal }) => {
    const [email, setEmail] = useState('');
    const [selectedRole, setSelectedRole] = useState('viewer');
    const [inviting, setInviting] = useState(false);
    const [error, setError] = useState('');
    const { workspaceData, fetchWorkspaceData } = useContext(WorkspaceContext);
    const { sendInvitationEmail } = useWorkspaceInvitationEmail(workspaceData);

    if (!showModal) return null;

    const handleInvite = async (e) => {
        e.preventDefault();
        setInviting(true);
        setError('');

        try {
            // 1. Crear/actualizar la invitación
            console.log('Sending invitation request...');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/workspace/members/invite`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ 
                    email, 
                    role: selectedRole,
                    workspaceId: workspaceData._id
                })
            });

            const data = await response.json();
            console.log('Invitation response:', data);

            if (!response.ok) {
                throw new Error(data.message || 'Error inviting member');
            }

            // 2. Enviar el email de invitación
            console.log('Sending invitation email...');
            const emailResult = await sendInvitationEmail(
                { email },
                data.invitationToken
            );

            console.log('Email result:', emailResult);

            if (!emailResult.success) {
                throw new Error(emailResult.message || 'Error sending invitation email');
            }

            // 3. Actualizar y cerrar
            await fetchWorkspaceData();
            setEmail('');
            setSelectedRole('viewer');
            closeModal();

        } catch (error) {
            console.error('Error in invitation process:', error);
            setError(error.message || 'Error sending invitation');
        } finally {
            setInviting(false);
        }
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <h2>Invite New Member</h2>
                {error && <p className="error-message">{error}</p>}
                <div className="input-group">
                    <label htmlFor="email">Email</label>
                    <input
                        id="email"
                        type="email"
                        className="input"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter email address"
                        required
                        disabled={inviting}
                    />
                </div>

                <div className="input-group">
                    <label htmlFor="role">Role</label>
                    <select 
                        id="role"
                        className="input"
                        value={selectedRole}
                        onChange={(e) => setSelectedRole(e.target.value)}
                        disabled={inviting}
                    >
                        <option value="viewer">Viewer</option>
                        <option value="editor">Editor</option>
                        <option value="admin">Admin</option>
                    </select>
                </div>

                <div className="modal-actions">
                    <button 
                        type="button" 
                        onClick={closeModal}
                        className="btn btn-outline-secondary"
                        disabled={inviting}
                    >
                        Cancel
                    </button>
                    <button 
                        onClick={handleInvite}
                        className="btn btn-fill-primary"
                        disabled={inviting}
                    >
                        {inviting ? 'Sending Invitation...' : 'Send Invitation'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default InviteMembersModal;
