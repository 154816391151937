import React, { useContext, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import UserContext from '../context/UserContext';
import { jwtDecode } from 'jwt-decode';

const ProtectedRoute = ({ children, skipWorkspaceValidation = false }) => {
    const { userData, loading, fetchUserData } = useContext(UserContext);
    const location = useLocation();
    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    useEffect(() => {
        const validateAuth = async () => {
            try {
                await fetchUserData();
            } catch (error) {
                // Verificar si el error es por token expirado
                if (error.response?.data?.code === 'TOKEN_EXPIRED') {
                    localStorage.removeItem('token');
                    navigate('/login?reason=session_expired');
                } else {
                    navigate('/login');
                }
            }
        };
        validateAuth();
    }, [fetchUserData, navigate]);

    if (loading) {
        return null;
    }

    if (!userData || !token) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (!userData.isEmailVerified) {
        return <Navigate to="/email-validation" replace />;
    }

    // Solo verificar workspace si no estamos en el selector
    if (!skipWorkspaceValidation) {
        const decodedToken = jwtDecode(token);
        if (!decodedToken.workspaceId && userData.memberOf?.length > 1) {
            return <Navigate to="/workspace-selector" replace />;
        }
    }

    return children;
};

export default ProtectedRoute; 