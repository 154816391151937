import React, { useMemo, useState } from 'react';
import { useTable, useFilters, useSortBy } from 'react-table';
import { Link } from 'react-router-dom';
import { DefaultColumnFilter, SelectColumnFilter, DateRangeColumnFilter } from './TableFilters';
import { ArrowDown, ArrowUp, Settings2, ChevronsLeft, ChevronLeft, ChevronRight, ChevronsRight } from 'lucide-react';
import './ArticlesTable.css';
import FiltersModal from './FiltersModal';
import TableTools from './TableTools';

const ArticlesTable = ({ 
    articles, 
    selectedArticles,
    toggleSelectAllArticles,
    toggleSelectArticle,
    openDeleteModal,
    workspaceLanguages,
    currentPage,
    totalPages,
    pageSize,
    onPageChange,
    onPageSizeChange,
    isLoading
}) => {
    const [showFilters, setShowFilters] = useState(false);

    const columns = useMemo(() => [
        {
            Header: () => (
                <input
                    type="checkbox"
                    checked={selectedArticles.length === articles.length}
                    onChange={toggleSelectAllArticles}
                />
            ),
            id: 'selection',
            Cell: ({ row }) => (
                <input
                    type="checkbox"
                    checked={selectedArticles.includes(row.original._id)}
                    onChange={() => toggleSelectArticle(row.original._id)}
                />
            ),
            Filter: () => null
        },
        {
            Header: 'Title',
            accessor: 'title',
            Cell: ({ row }) => (
                <Link to={`/articles/${row.original._id}`}>
                    {row.original.title}
                </Link>
            ),
            Filter: DefaultColumnFilter
        },
        {
            Header: 'Editable Content',
            accessor: 'editableContent.status',
            Cell: ({ value }) => value || 'Not started',
            Filter: SelectColumnFilter
        },
        ...workspaceLanguages.map(language => ({
            Header: language.label,
            accessor: `editableTranslations.${language.value}.status`,
            Cell: ({ value }) => value || 'Not started',
            Filter: SelectColumnFilter
        })),
        {
            Header: 'Created At',
            accessor: 'createdAt',
            Cell: ({ value }) => new Date(value).toLocaleString(),
            Filter: DateRangeColumnFilter,
            filter: 'dateBetween'
        }
    ], [workspaceLanguages, selectedArticles, articles.length, toggleSelectAllArticles, toggleSelectArticle]);

    const defaultColumn = useMemo(
        () => ({
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const filterTypes = useMemo(
        () => ({
            dateBetween: (rows, id, filterValue) => {
                const [start, end] = filterValue || [];
                
                if (!start && !end) return rows;

                return rows.filter(row => {
                    const rowDate = new Date(row.values[id]);
                    const startDate = start ? new Date(start) : null;
                    const endDate = end ? new Date(end) : null;

                    if (startDate && endDate) {
                        return rowDate >= startDate && rowDate <= endDate;
                    } else if (startDate) {
                        return rowDate >= startDate;
                    } else if (endDate) {
                        return rowDate <= endDate;
                    }
                    return true;
                });
            },
        }),
        []
    );

    const tableInstance = useTable(
        {
            columns,
            data: articles,
            defaultColumn,
            filterTypes
        },
        useFilters,
        useSortBy
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance;

    const getActiveFiltersCount = () => {
        return headerGroups[0].headers.reduce((count, header) => {
            if (header.filterValue && header.id !== 'selection') {
                return count + 1;
            }
            return count;
        }, 0);
    };

    const handlePageChange = (newPage) => {
        onPageChange(newPage + 1);
    };

    const handlePageSizeChange = (e) => {
        const newSize = Number(e.target.value);
        onPageSizeChange(newSize);
    };

    return (
        <div className="articles-table-module">
            <div className="table-header">
                <div className="table-actions">
                    <div className="search-container">
                        <input
                            type="text"
                            placeholder="Search by title"
                            className="search-input"
                            value={headerGroups[0].headers.find(header => header.id === 'title')?.filterValue || ''}
                            onChange={e => {
                                const titleColumn = headerGroups[0].headers.find(header => header.id === 'title');
                                if (titleColumn) {
                                    titleColumn.setFilter(e.target.value || undefined);
                                }
                            }}
                        />
                    </div>

                    <div className="filters-button">
                        <button 
                            onClick={() => setShowFilters(true)}
                            className="btn btn-fill-secondary"
                        >
                            <Settings2 className="w-5 h-5" />
                            <span style={{ marginLeft: 'var(--spacing-200)' }}>Filters</span>
                        </button>
                        {getActiveFiltersCount() > 0 && (
                            <span className="filters-count">
                                {getActiveFiltersCount()}
                            </span>
                        )}
                    </div>
                </div>
            </div>

            <div className="table-content">
                <div className="table-container">
                    {isLoading ? (
                        <div className="table-loading">
                            <div className="loading-spinner"></div>
                            <span>Cargando artículos...</span>
                        </div>
                    ) : (
                        <table {...getTableProps()} className="articles-table">
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                <div>
                                                    <span>{column.render('Header')}</span>
                                                    {column.isSorted && (
                                                        column.isSortedDesc 
                                                            ? <ArrowDown size={20} />
                                                            : <ArrowUp size={20} />
                                                    )}
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map(row => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => (
                                                <td {...cell.getCellProps()}>
                                                    {cell.render('Cell')}
                                                </td>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>

            <div className="table-footer">
                <div className="pagination">
                    <button 
                        onClick={() => handlePageChange(0)} 
                        disabled={currentPage === 1 || isLoading}
                    >
                        <ChevronsLeft size={20} />
                    </button>
                    <button 
                        onClick={() => handlePageChange(currentPage - 2)} 
                        disabled={currentPage === 1 || isLoading}
                    >
                        <ChevronLeft size={20} />
                    </button>
                    <span>
                        Página{' '}
                        <strong>{currentPage} de {totalPages}</strong>
                    </span>
                    <button 
                        onClick={() => handlePageChange(currentPage)} 
                        disabled={currentPage === totalPages || isLoading}
                    >
                        <ChevronRight size={20} />
                    </button>
                    <button 
                        onClick={() => handlePageChange(totalPages - 1)} 
                        disabled={currentPage === totalPages || isLoading}
                    >
                        <ChevronsRight size={20} />
                    </button>
                    <select
                        value={pageSize}
                        onChange={handlePageSizeChange}
                        disabled={isLoading}
                    >
                        {[10, 20, 30, 40, 50].map(size => (
                            <option key={size} value={size}>
                                Mostrar {size}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <FiltersModal 
                isOpen={showFilters}
                onClose={() => setShowFilters(false)}
                headerGroups={headerGroups}
            />

            {selectedArticles.length > 0 && (
                <TableTools 
                    selectedCount={selectedArticles.length}
                    onRemove={() => openDeleteModal(selectedArticles)}
                    onClose={() => {
                        toggleSelectAllArticles();
                    }}
                />
            )}
        </div>
    );
};

export default ArticlesTable;
