import React, { useEffect, useState, useCallback, useRef } from 'react';
import ReactQuill from 'react-quill';
import DOMPurify from 'dompurify';
import 'react-quill/dist/quill.snow.css';
import './EditableContent.css';
import ConfirmReviewLanguageModal from '../Modals/ConfirmReviewLanguageModal';

const EditableLanguage = ({ articleId, language }) => {
  const [editableContent, setEditableContent] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isContentGenerating, setIsContentGenerating] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [contentStatus, setContentStatus] = useState('Untranslated');
  const [showModal, setShowModal] = useState(false);
  const [translationStatus, setTranslationStatus] = useState('Untranslated');
  const [error, setError] = useState(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [saveRetryCount, setSaveRetryCount] = useState(0);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  
  // Referencias para manejo de Quill
  const quillRef = useRef(null);
  const changeRef = useRef(null);
  const saveIntervalRef = useRef(null);
  const saveRetryTimeoutRef = useRef(null);

  const getStatusMessage = (status) => {
    switch (status) {
      case 'Untranslated':
        return 'El contenido editable debe marcarse como revisado para traducir.';
      case 'In Progress':
        return 'La traducción se está generando; esto puede tardar unos minutos.';
      case 'Started':
        return 'Traducción iniciada';
      case 'Generated':
        return ''; // No message needed, will show the editor
      case 'Error':
        return 'Ocurrió un error durante la traducción. Por favor, inténtalo de nuevo.';
      default:
        return 'El contenido editable debe marcarse como revisado para traducir.';
    }
  };

  const handleContentChange = async (content) => {
    // Solo actualizamos el estado local, los cambios reales son trackados por Quill
    const sanitizedContent = DOMPurify.sanitize(content.trim());
    if (sanitizedContent !== editableContent) {
      setEditableContent(sanitizedContent);
    }
  };

  const handleSave = useCallback(async (force = false) => {
    // Solo guardar si hay cambios
    if (!force && (!isEditing || !changeRef.current || changeRef.current.ops.length === 0)) return;
    
    // No intentar guardar si no hay conexión a internet
    if (!navigator.onLine) {
      console.log('Sin conexión a internet. No se puede guardar en este momento.');
      setError('Sin conexión a internet. Los cambios se guardarán cuando la conexión se restablezca.');
      return;
    }
    
    setIsSaving(true);
    setError(null);
    try {
      console.log('=== INICIO GUARDADO TRADUCCIÓN ===');
      console.log('Guardando traducción...', {
        articleId,
        language,
        contentLength: editableContent.length,
        hasChanges: changeRef.current?.ops.length > 0,
        forzado: force,
        isEditing,
        intentoNúmero: saveRetryCount + 1
      });

      // Detalles para depuración
      console.log('Contenido a guardar (primeros 100 caracteres):', editableContent.substring(0, 100) + '...');
      const jsonBody = JSON.stringify({ content: editableContent });
      console.log('Body de la petición (primeros 100 caracteres):', jsonBody.substring(0, 100) + '...');
      console.log('Tamaño total del body:', jsonBody.length, 'bytes');

      const apiUrl = `${process.env.REACT_APP_API_URL}/articles/${articleId}/editableTranslations/${language}`;
      console.log('Enviando petición PUT a:', apiUrl);
      
      // Comprobar token
      const token = localStorage.getItem('token');
      console.log('Token disponible:', !!token);
      
      // Mostrar todas las opciones de fetch para depuración
      const fetchOptions = {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: jsonBody,
      };
      console.log('Opciones de fetch:', JSON.stringify(fetchOptions, (key, value) => {
        // No mostrar el body completo para evitar llenar la consola
        if (key === 'body') return 'BODY-CONTENT'; 
        return value;
      }, 2));
      
      console.log('Iniciando petición fetch...');
      const response = await fetch(apiUrl, fetchOptions);
      console.log('Petición fetch completada');

      console.log('Respuesta recibida:', {
        status: response.status,
        statusText: response.statusText,
        ok: response.ok,
        headers: Object.fromEntries([...response.headers.entries()])
      });

      if (!response.ok) {
        let errorText = '';
        try {
          errorText = await response.text();
        } catch (e) {
          errorText = 'No se pudo leer el cuerpo de la respuesta: ' + e.message;
        }
        
        console.error('Error en la respuesta del servidor:', {
          status: response.status,
          statusText: response.statusText,
          errorText,
          url: apiUrl
        });
        throw new Error(`Failed to save translation: ${response.status} ${response.statusText}`);
      }

      console.log('Traducción guardada con éxito');

      // Limpiamos cualquier error previo
      setError(null);
      
      // Resetear contador de intentos
      if (saveRetryCount > 0) {
        setSaveRetryCount(0);
      }

      // Resetear Delta después de guardar con éxito
      if (changeRef.current && quillRef.current) {
        changeRef.current = quillRef.current.getEditor().constructor.import('delta')();
      }
      setIsEditing(false);
      console.log('=== FIN GUARDADO TRADUCCIÓN ===');
    } catch (error) {
      console.error('Error detallado al guardar traducción:', {
        name: error.name,
        message: error.message,
        stack: error.stack,
        articleId,
        language,
        intentoNúmero: saveRetryCount + 1
      });
      
      // Intentar de nuevo si es un error de red o del servidor
      if (saveRetryCount < 3) {
        const nextRetryCount = saveRetryCount + 1;
        setSaveRetryCount(nextRetryCount);
        
        // Retrasos exponenciales: 5s, 10s, 20s
        const retryDelay = Math.pow(2, nextRetryCount) * 2500;
        console.log(`Reintentando guardado en ${retryDelay/1000} segundos...`);
        
        // Limpiar timeout anterior si existe
        if (saveRetryTimeoutRef.current) {
          clearTimeout(saveRetryTimeoutRef.current);
        }
        
        // Configurar nuevo timeout para reintento
        saveRetryTimeoutRef.current = setTimeout(() => {
          if (changeRef.current && changeRef.current.ops && changeRef.current.ops.length > 0) {
            console.log(`Reintentando guardado automático (intento ${nextRetryCount + 1})...`);
            handleSave(true);
          }
        }, retryDelay);
        
        setError(`Error al guardar: ${error.message}. Reintentando automáticamente...`);
      } else {
        setError(`Error al guardar después de varios intentos: ${error.message}. Por favor, utiliza el botón "Guardar manualmente".`);
        setSaveRetryCount(0);
      }
    } finally {
      setIsSaving(false);
    }
  }, [articleId, language, editableContent, isEditing, saveRetryCount]);

  useEffect(() => {
    const fetchTranslationStatus = async () => {
      try {
        const translationsUrl = `${process.env.REACT_APP_API_URL}/articles/${articleId}/translations`;
        const contentUrl = `${process.env.REACT_APP_API_URL}/articles/${articleId}/editableTranslations/${language}`;
        
        console.log('Cargando traducción desde:', {
          translationsUrl,
          contentUrl
        });

        const [translationsResponse, contentResponse] = await Promise.all([
          fetch(translationsUrl, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            credentials: 'include'
          }),
          fetch(contentUrl, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            credentials: 'include'
          })
        ]);

        if (!translationsResponse.ok || !contentResponse.ok) {
          const [translationsError, contentError] = await Promise.all([
            translationsResponse.ok ? null : translationsResponse.text(),
            contentResponse.ok ? null : contentResponse.text()
          ]);
          
          console.error('Error al cargar traducciones:', {
            translationsStatus: translationsResponse.status,
            translationsStatusText: translationsResponse.statusText,
            translationsError,
            contentStatus: contentResponse.status,
            contentStatusText: contentResponse.statusText,
            contentError
          });
          
          throw new Error('Failed to fetch translation data');
        }

        const [translationsData, contentData] = await Promise.all([
          translationsResponse.json(),
          contentResponse.json()
        ]);

        // Procesar datos de traducción
        const translation = translationsData.translations?.[language];
        if (translation) {
          setTranslationStatus(translation.status);
          setIsContentGenerating(translation.status !== 'Generated');
        }

        // Cargar contenido solo en la carga inicial
        if (contentData.content && !initialLoadComplete) {
          const sanitizedContent = DOMPurify.sanitize(contentData.content);
          setEditableContent(sanitizedContent);
          setInitialLoadComplete(true);
          setIsContentGenerating(false);
          setContentStatus(contentData.status || 'Untranslated');
        }
      } catch (error) {
        console.error('Error detallado al cargar traducción:', {
          message: error.message,
          stack: error.stack,
          articleId,
          language
        });
        setError(`Error al cargar: ${error.message}. Por favor, recarga la página.`);
      }
    };

    fetchTranslationStatus();
    
    // Solo verificar actualizaciones del estado de traducción,
    // no del contenido
    const intervalId = setInterval(() => {
      if (!initialLoadComplete) {
        fetchTranslationStatus();
      }
    }, 5000);

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [articleId, language, initialLoadComplete]);

  // Manejo de estado de conexión
  useEffect(() => {
    const handleOnline = () => {
      console.log('Conexión restablecida');
      setIsOnline(true);
      
      // Intentar guardar cambios pendientes cuando vuelve la conexión
      if (changeRef.current && changeRef.current.ops && changeRef.current.ops.length > 0) {
        console.log('Intentando guardar cambios pendientes después de reconexión');
        handleSave(true);
      }
    };
    
    const handleOffline = () => {
      console.log('Conexión perdida');
      setIsOnline(false);
    };
    
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [handleSave]);

  // Configuración del autosave usando la API nativa de Quill
  useEffect(() => {
    if (!quillRef.current || !initialLoadComplete) return;

    try {
      const quill = quillRef.current.getEditor();
      if (!quill) {
        console.error('No se pudo obtener la instancia de Quill');
        return;
      }

      // Importar Delta desde Quill
      const Delta = quill.constructor.import('delta');
      if (!Delta) {
        console.error('No se pudo importar Delta de Quill');
        return;
      }
      
      // Configurar para acumular cambios
      changeRef.current = new Delta();

      // Escuchar cambios en el texto
      const textChangeHandler = (delta) => {
        if (changeRef.current) {
          changeRef.current = changeRef.current.compose(delta);
          if (!isEditing) {
            setIsEditing(true);
          }
        }
      };

      quill.on('text-change', textChangeHandler);

      // Configurar intervalo para guardar periódicamente
      saveIntervalRef.current = setInterval(() => {
        if (isOnline && changeRef.current && changeRef.current.ops && changeRef.current.ops.length > 0) {
          handleSave();
        }
      }, 5000); // Guardar cada 5 segundos si hay cambios

      return () => {
        quill.off('text-change', textChangeHandler);
        if (saveIntervalRef.current) {
          clearInterval(saveIntervalRef.current);
        }
        if (saveRetryTimeoutRef.current) {
          clearTimeout(saveRetryTimeoutRef.current);
        }
        
        // Guardar cualquier cambio pendiente al desmontar
        if (changeRef.current && changeRef.current.ops && changeRef.current.ops.length > 0) {
          handleSave();
        }
      };
    } catch (err) {
      console.error('Error al inicializar el editor:', err);
      setError('Error al configurar el editor. Por favor recarga la página.');
    }
  }, [initialLoadComplete, isOnline, isEditing, handleSave]);

  // Guardar al desmontar o abandonar la página
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (changeRef.current && changeRef.current.ops && changeRef.current.ops.length > 0) {
        // Intentar guardar
        handleSave();
        
        // Mensaje de confirmación al usuario
        e.preventDefault();
        e.returnValue = '¿Seguro que quieres abandonar la página? Hay cambios sin guardar.';
        return e.returnValue;
      }
    };

    const handleVisibilityChange = () => {
      if (document.hidden && changeRef.current && changeRef.current.ops && changeRef.current.ops.length > 0) {
        handleSave();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      
      // Guardar cuando el componente se desmonte
      if (changeRef.current && changeRef.current.ops && changeRef.current.ops.length > 0) {
        handleSave();
      }
    };
  }, [handleSave]);

  const handleVerify = async () => {
    // Guardar primero
    await handleSave();
    
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/articles/${articleId}/editableTranslations/${language}/status`;
      const response = await fetch(apiUrl, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ status: 'Reviewed' }),
      });

      if (!response.ok) {
        throw new Error(`Failed to mark translation for ${language} as reviewed`);
      }

      setContentStatus('Reviewed');
      setShowModal(false);
    } catch (error) {
      console.error(`Error marking translation as reviewed for ${language}:`, error);
      setError(error.message);
    }
  };

  const handleOpenModal = async () => {
    // Guardar antes de abrir el modal
    await handleSave();
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmReview = () => {
    setShowModal(false);
    handleVerify();
  };
  
  const hasUnsavedChanges = () => {
    return changeRef.current && changeRef.current.ops && changeRef.current.ops.length > 0;
  };

  return (
    <div className="editable-content">
      {error && <div className="error-message">{error}</div>}
      {!isOnline && (
        <div className="connection-warning">
          Trabajando sin conexión. Los cambios se guardarán cuando vuelva la conexión a internet.
        </div>
      )}
      {isContentGenerating ? (
        <p>{getStatusMessage(translationStatus)}</p>
      ) : (
        <>
          <div className="editor-container">
            <ReactQuill
              ref={quillRef}
              value={editableContent}
              onChange={handleContentChange}
              modules={{
                toolbar: [
                  [{ 'header': [1, 2, false] }],
                  ['bold', 'italic', 'underline'],
                  ['link'],
                  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                  ['clean'],
                ],
                clipboard: {
                  matchVisual: false,
                },
              }}
              className="quill-editor"
            />
          </div>
          <div className="footer">
            <span className="status-text">
              {isSaving 
                ? 'Guardando automáticamente...' 
                : hasUnsavedChanges() 
                  ? 'Cambios pendientes de guardar' 
                  : 'Cambios guardados automáticamente'}
              {saveRetryCount > 0 && ` (Reintento ${saveRetryCount}/3)`}
            </span>
            <div className="button-group">
              <button 
                className="save-manual-btn" 
                onClick={() => handleSave(true)}
                disabled={isSaving || !isOnline}
              >
                {isSaving ? 'Guardando...' : 'Guardar manualmente'}
              </button>
              <button 
                onClick={handleOpenModal} 
                disabled={contentStatus === 'Reviewed' || !isOnline}
                className="btn-fill-primary"
              >
                {contentStatus === 'Reviewed' ? 'Revisado' : 'Marcar como Revisado'}
              </button>
            </div>
          </div>
          <ConfirmReviewLanguageModal
            showModal={showModal}
            closeModal={handleCloseModal}
            handleConfirm={handleConfirmReview}
          />
        </>
      )}
    </div>
  );
};

export default EditableLanguage;
