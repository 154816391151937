import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../../context/UserContext';
import './SignupWorkspace.css';
import '../../../styles/Button.css';
import '../../../styles/Input.css';

const SignupWorkspace = () => {
    const [workspaceName, setWorkspaceName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { fetchUserData } = useContext(UserContext);
    const navigate = useNavigate();

    const handleWorkspace = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            setErrorMessage('');
            
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No se encontró el token de autenticación');
            }

            console.log('Enviando petición a:', `${process.env.REACT_APP_API_URL}/auth/signupWorkspace`);
            
            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/signupWorkspace`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include',
                body: JSON.stringify({ 
                    workspace: workspaceName 
                }),
            });

            console.log('Status de la respuesta:', response.status);
            
            // Manejo específico para errores comunes
            if (response.status === 405) {
                throw new Error('Método no permitido. Verifica la URL y el método de la petición.');
            } else if (response.status === 404) {
                throw new Error('Ruta no encontrada. Verifica la URL de la API.');
            } else if (response.status === 401) {
                throw new Error('No autorizado. El token puede haber expirado.');
            } else if (response.status === 403) {
                throw new Error('Acceso prohibido. Verifica los permisos.');
            }

            // Intentar parsear la respuesta como JSON
            let data;
            try {
                data = await response.json();
            } catch (jsonError) {
                console.error('Error al parsear JSON:', jsonError);
                throw new Error('Error en el formato de respuesta del servidor');
            }
            
            // Verificar respuesta
            if (!response.ok) {
                throw new Error(data.message || 'Error al crear el workspace');
            }

            if (data.token) {
                console.log('Token final recibido:', data.token);
                localStorage.setItem('token', data.token);
                await fetchUserData();
                navigate('/signup-languages');
            } else {
                throw new Error(data.message || 'No se recibió el token del servidor');
            }
        } catch (error) {
            console.error('Error durante la creación del workspace:', error);
            setErrorMessage(error.message || 'Ocurrió un error. Por favor intenta de nuevo.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="workspace-container">
            <form className="workspace-form" onSubmit={handleWorkspace}>
                <h2 className="workspace-title">Create your workspace</h2>
                <p className="workspace-description">
                    Your workspace is where you and your team will manage all your content.
                </p>
                
                {errorMessage && (
                    <div className="error-message">
                        {errorMessage}
                    </div>
                )}
                
                <div className="input-group">
                    <label className="input-group-label" htmlFor="workspace-name">
                        Workspace Name
                    </label>
                    <input
                        className="input"
                        type="text"
                        id="workspace-name"
                        value={workspaceName}
                        onChange={(e) => setWorkspaceName(e.target.value)}
                        placeholder="Enter your workspace name"
                        required
                        disabled={isLoading}
                    />
                </div>

                <button 
                    className={`btn btn-fill-primary ${isLoading ? 'loading' : ''}`}
                    type="submit"
                    disabled={isLoading}
                >
                    {isLoading ? 'Procesando...' : 'Continue'}
                </button>
            </form>
        </div>
    );
};

export default SignupWorkspace;
