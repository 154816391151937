import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { WorkspaceContext } from '../../../../context/WorkspaceContext';
import './PlansAndBillingPaymentSummary.css';

const PlansAndBillingPaymentSummary = ({ selectedPlan, onCancel }) => {
    const navigate = useNavigate();
    const { workspaceMembers } = useContext(WorkspaceContext);
    
    if (!selectedPlan) {
        navigate('/settings/plan-and-billing');
        return null;
    }

    // Filtrar solo usuarios admin y editor
    const billableUsers = workspaceMembers?.filter(
        member => (member.role === 'admin' || member.role === 'editor') && member.status === 'active'
    ) || [];

    const getAvatarUrl = (member) => {
        if (!member.avatar) return null;
        return member.avatar.startsWith('http') 
            ? member.avatar 
            : member.avatar.startsWith('/uploads')
                ? `${window.location.origin}${member.avatar}`
                : `${window.location.origin}/${member.avatar}`;
    };

    const handleContinuePayment = async () => {
        try {
            if (billableUsers.length === 0) {
                alert('You need at least one billable user (admin or editor) to upgrade');
                return;
            }

            const response = await fetch(`${process.env.REACT_APP_API_URL}/stripe/create-checkout-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ 
                    planId: selectedPlan.id,
                    planName: selectedPlan.name.toLowerCase(),
                    quantity: billableUsers.length,
                    pricePerSeat: selectedPlan.price,
                    totalAmount: selectedPlan.price * billableUsers.length
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Error creating checkout session');
            }

            const { url } = await response.json();
            if (url) {
                window.location.href = url;
            } else {
                throw new Error('No checkout URL received');
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
            alert(error.message || 'There was an error processing your request. Please try again.');
        }
    };

    return (
        <div className="payment-summary-page">
            <div className="payment-summary-container">
                <div className="payment-summary-left">
                    <img src="/images/knowhub_logo.svg" alt="KnowHub Logo" style={{ width: '200px', marginBottom: 'var(--spacing-500)' }} />
                    <h2>Upgrade to {selectedPlan.name}</h2>
                    
                    <div className="plan-details">
                        <h3>Billing cycle</h3>
                        <div className="plan-price-container">
                            <h4>Monthly</h4>
                         <p className="plan-price">${selectedPlan.price}/month per user</p>

                        </div>
                    </div>

                    <div className="billable-users">
                        <h3>Billable Users</h3>
                        <div className="users-list">
                            {billableUsers.map((member) => (
                                <div key={member._id} className="user-item">
                                    <div className="user-info">
                                        {member.avatar ? (
                                            <img 
                                                src={getAvatarUrl(member)}
                                                alt=""
                                                className="user-avatar"
                                            />
                                        ) : (
                                            <div className="avatar-placeholder">
                                                {(member.email || '').charAt(0)}
                                            </div>
                                        )}
                                        <div className="user-details">
                                            <p className="user-name">{member.name}</p>
                                            <p className="user-email">{member.email}</p>
                                        </div>
                                    </div>
                                    <span className={`role-badge ${member.role}`}>
                                        {member.role}
                                    </span>
                                </div>
                            ))}
                        </div>
                        <button 
                            className="btn btn-flat-primary"
                            onClick={() => navigate('/settings/members')}
                        >
                            Manage members
                        </button>
                    </div>
                </div>

                <div className="payment-summary-right">
                    <div className="order-summary">
                        <h2>Order summary</h2>
                        
                        <div className="price-breakdown">
                            <div className="plan-name">
                                <div className="plan-name-container">
                                    <h3>{selectedPlan.name} - Monthly</h3>
                                    <p className="plan-price">
                                        ${selectedPlan.price} USD × {billableUsers.length} {billableUsers.length === 1 ? 'User' : 'Users'}
                                    </p>
                                </div>
                                <div className="plan-price-container">
                                    <p>${selectedPlan.price * billableUsers.length} USD</p>
                                </div>
                            </div>
                            
                            <div className="total-section">
                                <h3>Billed monthly</h3>
                                <p className="total-amount">
                                    ${selectedPlan.price * billableUsers.length} USD
                                </p>
                            </div>
                        </div>
                        <button 
                            className="btn btn-fill-primary"
                            onClick={handleContinuePayment}
                            disabled={billableUsers.length === 0}
                        >
                            {billableUsers.length === 0 
                                ? 'Add billable users to continue'
                                : 'Continue to payment details'
                            }
                        </button>
                    </div>
                    <div className="btn-container">
                        <button 
                            className="btn btn-outline-secondary"
                            onClick={() => navigate('/settings/plan-and-billing')}
                            style={{ maxWidth: '9rem' }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlansAndBillingPaymentSummary;
