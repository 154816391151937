import React, { useState } from 'react';
import Select from 'react-select';

const AddLanguageModal = ({ showModal, closeModal, currentLanguages, availableLanguages, onLanguagesUpdate }) => {
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const addLanguage = async () => {
        if (!selectedLanguage) {
            setError('Please select a language');
            return;
        }

        try {
            setIsLoading(true);
            setError('');
            
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No se encontró el token de autenticación');
            }

            console.log('Enviando petición a:', `${process.env.REACT_APP_API_URL}/settings/languages`);
            
            const response = await fetch(`${process.env.REACT_APP_API_URL}/settings/languages`, {
                method: 'PUT',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include',
                body: JSON.stringify({
                    languages: [...currentLanguages.map(lang => lang.value), selectedLanguage.value],
                }),
            });

            console.log('Status de la respuesta:', response.status);
            
            // Manejo específico para errores comunes
            if (response.status === 404) {
                throw new Error('Ruta no encontrada. Verifica la URL de la API.');
            } else if (response.status === 401) {
                throw new Error('No autorizado. El token puede haber expirado.');
            }

            // Intentar parsear la respuesta como JSON si hay contenido
            let data;
            try {
                if (response.headers.get('content-length') !== '0') {
                    data = await response.json();
                }
            } catch (jsonError) {
                console.error('Error al parsear JSON:', jsonError);
                throw new Error('Error en el formato de respuesta del servidor');
            }
            
            if (!response.ok) {
                throw new Error(data?.message || 'Error al actualizar los idiomas');
            }

            await onLanguagesUpdate();
            closeModal();
            setSelectedLanguage(null);
        } catch (error) {
            console.error('Error updating languages:', error);
            setError(error.message || 'Error al actualizar los idiomas');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        showModal && (
            <div className="modal">
                <div className="modal-content">
                    <h2>Add New Language</h2>
                    {error && <div className="error-message">{error}</div>}
                    <Select
                        options={availableLanguages.filter(
                            (lang) => !currentLanguages.some((existing) => existing.value === lang.value)
                        )}
                        value={selectedLanguage}
                        onChange={(selected) => {
                            setSelectedLanguage(selected);
                            setError('');
                        }}
                        placeholder="Select a language"
                        isDisabled={isLoading}
                    />
                    <div className="modal-actions">
                        <button 
                            className="btn btn-outline-secondary" 
                            onClick={closeModal}
                            disabled={isLoading}
                        >
                            Cancel
                        </button>
                        <button 
                            className={`btn btn-fill-primary ${isLoading ? 'loading' : ''}`} 
                            onClick={addLanguage}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Adding...' : 'Add language'}
                        </button>
                    </div>
                </div>
            </div>
        )
    );
};

export default AddLanguageModal;
