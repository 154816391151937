import React, { createContext, useState, useEffect, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchUserData = useCallback(async (skipRedirect = false) => {
        const registrationPaths = ['/signup', '/SignupWorkspace', '/signup-languages', '/email-validation'];
        const currentPath = window.location.pathname;

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                setUserData(null);
                setLoading(false);
                return null;
            }

            const decodedToken = jwtDecode(token);
            const isInRegistrationFlow = registrationPaths.includes(currentPath);

            // Para el flujo de registro inicial, usamos solo los datos del token
            if (isInRegistrationFlow && currentPath !== '/email-validation') {
                const basicUserData = {
                    id: decodedToken.id,
                    name: decodedToken.name,
                    email: decodedToken.email,
                    isEmailVerified: decodedToken.isEmailVerified
                };
                setUserData(basicUserData);
                setLoading(false);
                return basicUserData;
            }

            // Para todas las demás rutas, incluida la validación de email,
            // obtenemos datos frescos del backend
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/userdata`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Error fetching user data');
                }

                const data = await response.json();
                const updatedUserData = {
                    ...data,
                    isEmailVerified: data.isEmailVerified
                };

                // Añadir el rol del workspace actual si existe
                if (data.memberOf && decodedToken.workspaceId) {
                    const currentWorkspaceRole = data.memberOf.find(
                        membership => membership.workspace._id === decodedToken.workspaceId
                    )?.role || 'viewer';
                    updatedUserData.currentWorkspaceRole = currentWorkspaceRole;
                }

                setUserData(updatedUserData);
                setLoading(false);

                // Manejo de redirecciones
                if (!skipRedirect && !isInRegistrationFlow) {
                    if (!data.memberOf || data.memberOf.length === 0) {
                        window.location.href = '/SignupWorkspace';
                        return null;
                    } else if (data.memberOf.length === 1 && !decodedToken.workspaceId) {
                        const workspaceId = data.memberOf[0].workspace._id;
                        const selectResponse = await fetch(`${process.env.REACT_APP_API_URL}/auth/select-workspace`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            },
                            credentials: 'include',
                            body: JSON.stringify({ workspaceId })
                        });
                        
                        if (selectResponse.ok) {
                            const { token: newToken } = await selectResponse.json();
                            localStorage.setItem('token', newToken);
                            window.location.href = '/home';
                            return null;
                        }
                    }
                }

                return updatedUserData;

            } catch (error) {
                console.error('Error fetching user data:', error);
                if (!isInRegistrationFlow) {
                    localStorage.removeItem('token');
                    setUserData(null);
                }
                return null;
            }

        } catch (error) {
            console.error('Error in fetchUserData:', error);
            if (!registrationPaths.includes(currentPath)) {
                localStorage.removeItem('token');
                setUserData(null);
            }
            return null;
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token && !userData) {
            fetchUserData();
        } else {
            setLoading(false);
        }
    }, [fetchUserData, userData]);

    const value = {
        userData,
        loading,
        fetchUserData
    };

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;
