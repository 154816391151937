import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import NavBar from '../Navbar/NavBar';
import ModalDeleteArticle from './components/ModalDeleteArticle';
import { WorkspaceContext } from '../../context/WorkspaceContext';
import Header from '../Headers/Header';
import ArticlesTable from './components/ArticlesTable';
import UpgradeBanner from '../Banners/UpgradeBannerArticles/UpgradeBannerArticles';
import './Articles.css';

const ArticlesIndex = () => {
    const { workspaceData, workspaceLanguages, fetchWorkspaceData } = useContext(WorkspaceContext);
    const [articles, setArticles] = useState([]);
    const [articleLimit, setArticleLimit] = useState(null);
    const [currentCount, setCurrentCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [articleToDelete, setArticleToDelete] = useState(null);
    const [selectedArticles, setSelectedArticles] = useState([]);
    const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const fetchArticles = useCallback(async (page = 1, limit = pageSize) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/articles?page=${page}&limit=${limit}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    credentials: 'include'
                }
            );

            if (response.ok) {
                const data = await response.json();
                return {
                    articles: data.articles || [],
                    limit: data.limit,
                    current: data.current,
                    currentPage: data.currentPage,
                    totalPages: data.totalPages
                };
            }
            throw new Error('Failed to fetch articles');
        } catch (error) {
            console.error('Error fetching articles:', error);
            return { 
                articles: [], 
                limit: null, 
                current: 0,
                currentPage: 1,
                totalPages: 1
            };
        }
    }, [pageSize]);

    // Cargar datos del workspace solo una vez al inicio
    useEffect(() => {
        const loadWorkspaceData = async () => {
            try {
                await fetchWorkspaceData();
            } catch (error) {
                console.error('Error loading workspace data:', error);
            }
        };

        loadWorkspaceData();
    }, [fetchWorkspaceData]);

    // Cargar artículos cuando cambie la página
    useEffect(() => {
        const loadArticles = async () => {
            setIsLoading(true);
            try {
                const result = await fetchArticles(currentPage);
                if (result) {
                    setArticles(result.articles);
                    setArticleLimit(result.limit);
                    setCurrentCount(result.current);
                    setCurrentPage(result.currentPage);
                    setTotalPages(result.totalPages);
                }
            } catch (error) {
                console.error('Error loading articles:', error);
            } finally {
                setIsLoading(false);
            }
        };

        loadArticles();
    }, [currentPage, fetchArticles]);

    const handlePageChange = useCallback(async (newPage) => {
        setCurrentPage(newPage);
    }, []);

    const handlePageSizeChange = useCallback(async (newSize) => {
        setPageSize(newSize);
        setCurrentPage(1);
    }, []);

    const closeDeleteModal = useCallback(() => {
        setArticleToDelete(null);
        setShowDeleteModal(false);
    }, []);

    const handleDeleteArticle = useCallback(async () => {
        if (articleToDelete) {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`${process.env.REACT_APP_API_URL}/articles/${articleToDelete}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    credentials: 'include'
                });
                
                if (response.ok) {
                    const articlesResult = await fetchArticles(currentPage);
                    if (articlesResult) {
                        setArticles(articlesResult.articles);
                        setArticleLimit(articlesResult.limit);
                        setCurrentCount(articlesResult.current);
                        setCurrentPage(articlesResult.currentPage);
                        setTotalPages(articlesResult.totalPages);
                    }
                    closeDeleteModal();
                }
            } catch (error) {
                alert('Error deleting article');
            }
        }
    }, [articleToDelete, fetchArticles, currentPage, closeDeleteModal]);

    const handleBulkDeleteArticles = async () => {
        try {
            const token = localStorage.getItem('token');
            
            for (let id of selectedArticles) {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/articles/${id}`, { 
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    credentials: 'include'
                });
                if (!response.ok) throw new Error(`Failed to delete article with ID ${id}`);
            }
            
            const articlesResult = await fetchArticles(currentPage);
            if (articlesResult) {
                setArticles(articlesResult.articles);
                setArticleLimit(articlesResult.limit);
                setCurrentCount(articlesResult.current);
                setCurrentPage(articlesResult.currentPage);
                setTotalPages(articlesResult.totalPages);
            }
            setSelectedArticles([]);
            setShowBulkDeleteModal(false);
        } catch (error) {
            alert('Error during bulk delete');
        }
    };

    const openDeleteModal = useCallback((ids) => {
        if (Array.isArray(ids)) {
            setShowBulkDeleteModal(true);
        } else {
            setArticleToDelete(ids);
            setShowDeleteModal(true);
        }
    }, []);

    const toggleSelectArticle = useCallback((id) => {
        setSelectedArticles(prev =>
            prev.includes(id)
                ? prev.filter(articleId => articleId !== id)
                : [...prev, id]
        );
    }, []);

    const toggleSelectAllArticles = useCallback(() => {
        setSelectedArticles(prev =>
            prev.length === articles.length ? [] : articles.map(article => article._id)
        );
    }, [articles]);

    if (!workspaceData) {
        return <div className="error-state">No workspace data available. Please refresh the page.</div>;
    }

    return (
        <div className="container-horizontal">
            <Helmet>
                <title>Articles | KnowHub</title>
            </Helmet>
            <NavBar />
            <div className="main-content">
                <UpgradeBanner 
                    currentCount={currentCount}
                    articleLimit={articleLimit}
                />
                <Header 
                    title="Articles"
                    description="Manage and organize your articles"
                    actions={{
                        count: currentCount,
                        countLabel: `of ${articleLimit} articles`,
                        showCounter: true,
                        showButton: true,
                        buttonText: "New Article"
                    }}
                />
                
                <ArticlesTable 
                    articles={articles}
                    selectedArticles={selectedArticles}
                    toggleSelectAllArticles={toggleSelectAllArticles}
                    toggleSelectArticle={toggleSelectArticle}
                    openDeleteModal={openDeleteModal}
                    workspaceLanguages={workspaceLanguages || []}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    pageSize={pageSize}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    isLoading={isLoading}
                />

                <ModalDeleteArticle
                    showModal={showDeleteModal}
                    closeModal={closeDeleteModal}
                    handleDeleteArticle={handleDeleteArticle}
                />
                <ModalDeleteArticle
                    showModal={showBulkDeleteModal}
                    closeModal={() => {
                        setShowBulkDeleteModal(false);
                        setSelectedArticles([]);
                    }}
                    handleDeleteArticle={handleBulkDeleteArticles}
                    selectedCount={selectedArticles.length}
                />
            </div>
        </div>
    );
};

export default ArticlesIndex;
