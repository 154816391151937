import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './WorkspaceSelector.css';

const WorkspaceSelector = () => {
    const navigate = useNavigate();
    const [workspaces, setWorkspaces] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }

        const fetchWorkspaces = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/userdata/workspaces`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Error al cargar los workspaces');
                }

                const data = await response.json();
                setWorkspaces(data.workspaces || []);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchWorkspaces();
    }, [navigate]);

    const handleWorkspaceSelect = async (workspaceId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/select-workspace`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                credentials: 'include',
                body: JSON.stringify({ workspaceId })
            });

            if (!response.ok) {
                throw new Error('Error al seleccionar el workspace');
            }

            const data = await response.json();
            if (data.token) {
                localStorage.setItem('token', data.token);
                navigate('/home');
            }
        } catch (error) {
            setError(error.message);
        }
    };

    const handleCreateNewWorkspace = () => {
        navigate('/SignupWorkspace');
    };

    if (loading) {
        return (
            <div className="workspace-selector-container">
                <h2>Cargando workspaces...</h2>
                <p>Por favor espere mientras cargamos sus workspaces</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="workspace-selector-container">
                <h2>Error al cargar workspaces</h2>
                <p>{error}</p>
            </div>
        );
    }

    return (
        <div className="workspace-selector-container">
            <h2>Selecciona un Workspace</h2>
            <div className="workspaces-list">
                {workspaces && workspaces.length > 0 ? (
                    workspaces.map((workspace) => (
                        <div 
                            key={workspace._id} 
                            className="workspace-item"
                            onClick={() => handleWorkspaceSelect(workspace._id)}
                        >
                            <h3>{workspace.name}</h3>
                            <span className="role-badge">{workspace.role}</span>
                        </div>
                    ))
                ) : (
                    <p>No tienes workspaces disponibles. ¿Deseas crear uno nuevo?</p>
                )}
            </div>
            <button 
                className="btn btn-fill-primary"
                onClick={handleCreateNewWorkspace}
            >
                Crear Nuevo Workspace
            </button>
        </div>
    );
};

export default WorkspaceSelector;
