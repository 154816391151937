import React from 'react';
import '../../../../styles/Modal.css';
import '../../../../styles/Button.css';

const DowngradeConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
    if (!isOpen) return null;

    return (
        <div className="modal">
            <div className="modal-content">
                <h2>Confirm Downgrade to Starter Plan</h2>
                <p>Are you sure you want to downgrade to the Starter Plan? You will lose access to:</p>
                <ul style={{
                    color: 'var(--colors-gray-300)',
                    paddingLeft: 'var(--spacing-500)',
                    margin: '0'
                }}>
                    <li>Advanced editing features</li>
                    <li>Extended recording time</li>
                    <li>Additional languages</li>
                    <li>State management</li>
                </ul>
                <div className="modal-actions">
                    <button 
                        className="btn btn-outline-secondary" 
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button 
                        className="btn btn-fill-danger" 
                        onClick={onConfirm}
                    >
                        Confirm Downgrade
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DowngradeConfirmationModal; 