import React, { useContext, useState, useEffect } from 'react';
import { WorkspaceContext } from '../../../../context/WorkspaceContext';
import './PlansCards.css';

const PlansCards = ({ canManagePlans, currentPlan, onSelectPlan }) => {
    const { workspaceData } = useContext(WorkspaceContext);
    const [plans, setPlans] = useState({});

    useEffect(() => {
        const fetchPlans = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`${process.env.REACT_APP_API_URL}/plans`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    credentials: 'include'
                });
                
                if (response.ok) {
                    const plansData = await response.json();
                    setPlans(plansData);
                } else {
                    console.error('Error fetching plans:', await response.text());
                }
            } catch (error) {
                console.error('Error fetching plans:', error);
            }
        };

        fetchPlans();
    }, []);

    const renderButton = (plan, planKey) => {
        if (!canManagePlans) return null;

        const isCurrentPlan = currentPlan === planKey;
        if (isCurrentPlan) {
            return (
                <button 
                    className="btn btn-outline-primary" 
                    disabled
                >
                    Current Plan
                </button>
            );
        }

        // Determinar si es upgrade o downgrade
        const planOrder = { starter: 0, professional: 1, advanced: 2 };
        const isUpgrade = planOrder[planKey] > planOrder[currentPlan];

        return (
            <button 
                className="btn btn-fill-primary"
                onClick={() => onSelectPlan({
                    id: planKey,
                    name: planKey.charAt(0).toUpperCase() + planKey.slice(1),
                    price: plan.price || 0,
                    features: plan.features,
                    articleLimit: plan.articleLimit,
                    recordingLimit: plan.recordingLimit
                })}
            >
                {isUpgrade ? 'Upgrade to' : 'Switch to'} {planKey.charAt(0).toUpperCase() + planKey.slice(1)}
            </button>
        );
    };

    const renderPrice = (planDetails, planKey) => {
        if (planKey === 'starter') return 'Free';
        return `$${planDetails.price}/seat/month`;
    };

    return (
        <div className="plans-container">
            <h2>All Plans</h2>
            <div className="plans-cards">
                {Object.entries(plans).map(([planKey, planDetails]) => (
                    <div 
                        key={planKey} 
                        className={`plan-card ${workspaceData?.plan === planKey ? 'active' : ''}`}
                    >
                        <h3 className="plan-title">{planKey.charAt(0).toUpperCase() + planKey.slice(1)}</h3>
                        <div className="plan-price">
                            {renderPrice(planDetails, planKey)}
                        </div>
                        
                        <div className="plan-features">
                            <div className="feature">
                                <span>Articles: </span>
                                <span>{planDetails.articleLimit}</span>
                            </div>
                            <div className="feature">
                                <span>Recording: </span>
                                <span>{planDetails.recordingLimit} min</span>
                            </div>
                            
                            {/* Características básicas */}
                            <div className="feature">
                                {planDetails.features.screenRecording ? '✓' : '✗'} Screen Recording
                            </div>
                            <div className="feature">
                                {planDetails.features.eventLogging ? '✓' : '✗'} Event Logging
                            </div>
                            <div className="feature">
                                {planDetails.features.transcription ? '✓' : '✗'} Transcription
                            </div>
                            <div className="feature">
                                {planDetails.features.articleGeneration ? '✓' : '✗'} Article Generation
                            </div>
                            <div className="feature">
                                {planDetails.features.articleEditing ? '✓' : '✗'} Article Editing
                            </div>
                            <div className="feature">
                                {planDetails.features.stateManagement ? '✓' : '✗'} State Management
                            </div>

                            {/* Características de traducción */}
                            <div className="feature">
                                <span>Languages: </span>
                                <span>{planDetails.features.translation.languages}</span>
                            </div>
                            <div className="feature">
                                {planDetails.features.translation.editing ? '✓' : '✗'} Translation Editing
                            </div>
                            <div className="feature">
                                {planDetails.features.translation.stateManagement ? '✓' : '✗'} Translation State Management
                            </div>
                        </div>

                        {renderButton(planDetails, planKey)}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PlansCards;
