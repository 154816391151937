import React from 'react';
import { NavLink } from 'react-router-dom';
import '../../styles/Navbar-Secondary.css';

const SettingsSidebar = () => {
  return (
    <div className="sidebar-secondary">
      <h2 className="sidebar__title">Workspace Settings</h2>
      <nav>
        <ul className="sidebar__list">
          <li>
            <NavLink to="/settings" className="sidebar__link" end>
              General
            </NavLink>
          </li>
          <li>
            <NavLink to="/settings/localization" className="sidebar__link">
              Localization
            </NavLink>
          </li>
          <li>
            <NavLink to="/settings/plan-and-billing" className="sidebar__link">
              Plan & Billing
            </NavLink>
          </li>
          <li>
            <NavLink to="/settings/members" className="sidebar__link">
              Members
            </NavLink>
          </li>
          
        </ul>
      </nav>
    </div>
  );
};

export default SettingsSidebar;
