import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../../context/UserContext';
import AddLanguageModal from '../../Settings/Localization/AddLanguageModal';
import './SignupLanguages.css';
import Header from '../../Headers/Header';

const SignupLanguages = () => {
    const [availableLanguages, setAvailableLanguages] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const { fetchUserData, userData } = useContext(UserContext);

    useEffect(() => {
        fetchAvailableLanguages();
        fetchCurrentLanguages();
    }, []);

    const fetchAvailableLanguages = async () => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No se encontró el token de autenticación');
            }

            console.log('Consultando idiomas disponibles desde:', `${process.env.REACT_APP_API_URL}/settings/languages/available`);
            
            const response = await fetch(`${process.env.REACT_APP_API_URL}/settings/languages/available`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            });

            console.log('Status de la respuesta idiomas disponibles:', response.status);
            
            // Manejo específico para errores comunes
            if (response.status === 404) {
                throw new Error('Ruta no encontrada. Verifica la URL de la API.');
            } else if (response.status === 401) {
                throw new Error('No autorizado. El token puede haber expirado.');
            }

            // Intentar parsear la respuesta como JSON
            let data;
            try {
                data = await response.json();
            } catch (jsonError) {
                console.error('Error al parsear JSON (idiomas disponibles):', jsonError);
                throw new Error('Error en el formato de respuesta del servidor');
            }
            
            if (!response.ok) {
                throw new Error(data.message || 'Error al obtener idiomas disponibles');
            }

            setAvailableLanguages(data.languages.map((lang) => ({
                label: lang.name,
                value: lang.code,
            })));
        } catch (error) {
            console.error('Error fetching available languages:', error);
            setErrorMessage(error.message || 'Error al cargar los idiomas disponibles');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchCurrentLanguages = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No se encontró el token de autenticación');
            }

            console.log('Consultando idiomas actuales desde:', `${process.env.REACT_APP_API_URL}/settings/languages`);
            
            const response = await fetch(`${process.env.REACT_APP_API_URL}/settings/languages`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            });

            console.log('Status de la respuesta idiomas actuales:', response.status);
            
            // Manejo específico para errores comunes
            if (response.status === 404) {
                throw new Error('Ruta no encontrada. Verifica la URL de la API.');
            } else if (response.status === 401) {
                throw new Error('No autorizado. El token puede haber expirado.');
            }

            // Intentar parsear la respuesta como JSON
            let data;
            try {
                data = await response.json();
            } catch (jsonError) {
                console.error('Error al parsear JSON (idiomas actuales):', jsonError);
                throw new Error('Error en el formato de respuesta del servidor');
            }
            
            if (!response.ok) {
                throw new Error(data.message || 'Error al obtener idiomas actuales');
            }

            setSelectedLanguages(data.languages);
        } catch (error) {
            console.error('Error fetching current languages:', error);
            // No mostramos este error en la UI para no sobrecargar con mensajes
        }
    };

    const removeLanguage = async (language) => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No se encontró el token de autenticación');
            }

            const response = await fetch(`${process.env.REACT_APP_API_URL}/settings/languages`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include',
                body: JSON.stringify({ language }),
            });

            // Intentar parsear la respuesta como JSON si hay contenido
            if (response.headers.get('content-length') !== '0') {
                const data = await response.json();
                if (!response.ok) {
                    throw new Error(data.message || 'Error al eliminar el idioma');
                }
            } else if (!response.ok) {
                throw new Error('Error al eliminar el idioma');
            }

            await fetchCurrentLanguages();
        } catch (error) {
            console.error('Error removing language:', error);
            setErrorMessage(error.message || 'Error al eliminar el idioma');
        } finally {
            setIsLoading(false);
        }
    };

    const handleContinue = async () => {
        if (selectedLanguages.length === 0) {
            setErrorMessage('Por favor, selecciona al menos un idioma');
            return;
        }

        try {
            setIsLoading(true);
            setErrorMessage('');
            
            await fetchUserData();
            if (!userData?.isEmailVerified) {
                navigate('/email-validation');
            } else {
                navigate('/home');
            }
        } catch (error) {
            console.error('Error updating user data:', error);
            setErrorMessage('Error al actualizar los datos del usuario');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="signup-languages-container">
            <div className="signup-languages-content">
                <Header 
                    title="Select up to 3 target languages for your translations"
                    description="Languages for Translation."
                    actions={{
                        showCounter: true,
                        count: selectedLanguages.length,
                        countLabel: "of 3 languages",
                        showButton: true,
                        buttonText: "Add language",
                        buttonAction: () => setShowModal(true),
                        buttonDisabled: selectedLanguages.length >= 3 || isLoading
                    }}
                />
                
                {errorMessage && (
                    <div className="error-message">
                        {errorMessage}
                    </div>
                )}
                
                <div className="default-languages-list">
                    <div className="language-item">
                        <div className="default-language-info">
                            <span className="language-name"> Recording Language (default) </span> 
                            <span className="language-description"> The content will be created in the language spoken during the video recording. </span>   
                        </div>
                    </div>
                </div>

                <div className="languages-list">
                    {selectedLanguages.map((lang, index) => (
                        <div key={index} className="language-item">
                            <span className="language-name">{`${lang.label} (${lang.value})`}</span>
                            <button 
                                onClick={() => removeLanguage(lang)}
                                className="btn btn-fill-danger"
                                disabled={isLoading}
                            >
                                Remove
                            </button>
                        </div>
                    ))}
                </div>

                <div className="signup-languages-footer">
                    <div className="signup-languages-footer-info">
                        <p className="signup-languages-footer-info-text">
                            You can add more languages by upgrading your plan in the settings.
                        </p>
                    </div>
                    <button 
                        onClick={handleContinue}
                        className={`btn btn-fill-primary continue-btn ${isLoading ? 'loading' : ''}`}
                        disabled={selectedLanguages.length === 0 || isLoading}
                    >
                        {isLoading ? 'Procesando...' : 'Continue'}
                    </button>
                </div>

                <AddLanguageModal
                    showModal={showModal}
                    closeModal={() => setShowModal(false)}
                    currentLanguages={selectedLanguages}
                    availableLanguages={availableLanguages}
                    onLanguagesUpdate={fetchCurrentLanguages}
                />
            </div>
        </div>
    );
};

export default SignupLanguages; 