import React, { useEffect, useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { WorkspaceContext } from '../../../context/WorkspaceContext';
import Header from '../../Headers/Header';
import AddLanguageModal from './AddLanguageModal';
import ModalDeleteLanguage from './ModalDeleteLanguage';
import './LocalizationSettings.css';
import UpgradeBannerLanguage from '../../Banners/UpgradeBannerLanguage/UpgradeBannerLanguage';

const LocalizationSettings = ({ userRole }) => {
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [planLimit, setPlanLimit] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [languageToDelete, setLanguageToDelete] = useState(null);
  const { workspaceLanguages, fetchWorkspaceData } = useContext(WorkspaceContext);

  const canEditLanguages = userRole === 'admin';

  useEffect(() => {
    const initializeData = async () => {
      try {
        const token = localStorage.getItem('token');
        
        const [limitResponse, availableResponse] = await Promise.all([
          fetch(`${process.env.REACT_APP_API_URL}/settings/languages/limit`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            credentials: 'include'
          }),
          fetch(`${process.env.REACT_APP_API_URL}/settings/languages/available`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            credentials: 'include'
          })
        ]);

        if (limitResponse.ok) {
          const limitData = await limitResponse.json();
          setPlanLimit(limitData.limit);
        } else {
          console.error('Error fetching language limit:', await limitResponse.text());
        }

        if (availableResponse.ok) {
          const availableData = await availableResponse.json();
          setAvailableLanguages(
            availableData.languages.map((lang) => ({
              label: lang.name,
              value: lang.code,
            }))
          );
        } else {
          console.error('Error fetching available languages:', await availableResponse.text());
        }

        await fetchWorkspaceData();
      } catch (error) {
        console.error('Error initializing data:', error);
      }
    };

    initializeData();
  }, [fetchWorkspaceData]);

  const removeLanguage = async (language) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/settings/languages`, {
        method: 'DELETE',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include',
        body: JSON.stringify({ language }),
      });

      if (response.ok) {
        await fetchWorkspaceData();
        closeDeleteModal();
      } else {
        console.error('Error removing language:', await response.text());
      }
    } catch (error) {
      console.error('Error removing language:', error);
    }
  };

  const openDeleteModal = (language) => {
    setLanguageToDelete(language);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setLanguageToDelete(null);
    setShowDeleteModal(false);
  };

  return (
    <div className="localization-settings">
      <Helmet>
        <title>Localization | KnowHub</title>
      </Helmet>
      <Header 
        title="Languages for Translation"
        description="Here you can manage the target languages for your translations"
        actions={{
          showCounter: true,
          count: workspaceLanguages?.length || 0,
          countLabel: planLimit ? `of ${planLimit} languages` : 'languages',
          showButton: canEditLanguages,
          buttonText: "Add language",
          buttonAction: () => setShowModal(true),
          buttonDisabled: planLimit && workspaceLanguages?.length >= planLimit
        }}
      />
      
      <div className="default-languages-list">    
      {!canEditLanguages && (
        <div className="settings-info-message">
          Only administrators can modify language settings
        </div>
      )}
        <div className="language-item">
          <div className="default-language-info">
            <span className="language-name">Recording Language (default)</span> 
            <span className="language-description">
              The content will be created in the language spoken during the video recording.
            </span>   
          </div>
        </div>
      </div>

      <div className="languages-list">
        {workspaceLanguages?.map((lang, index) => (
          <div key={index} className="language-item">
            <span className="language-name">{`${lang.label} (${lang.value})`}</span>
            {canEditLanguages && (
              <button 
                onClick={() => openDeleteModal(lang)}
                className="btn btn-flat-danger"
              >
                Remove
              </button>
            )}
          </div>
        ))}
      </div>

      <UpgradeBannerLanguage languageLimit={planLimit} />

      {canEditLanguages && (
        <>
          <AddLanguageModal
            showModal={showModal}
            closeModal={() => setShowModal(false)}
            currentLanguages={workspaceLanguages || []}
            availableLanguages={availableLanguages}
            onLanguagesUpdate={fetchWorkspaceData}
          />

          <ModalDeleteLanguage
            showModal={showDeleteModal}
            closeModal={closeDeleteModal}
            handleDeleteLanguage={() => removeLanguage(languageToDelete)}
            language={languageToDelete}
          />
        </>
      )}
    </div>
  );
};

export default LocalizationSettings;
