import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './styles/variables.css';
import './App.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './context/UserContext';
import { GoogleOAuthProvider } from '@react-oauth/google';

// Validar las variables de entorno críticas
const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const apiUrl = process.env.REACT_APP_API_URL;

if (!clientId) {
    console.error('ERROR: REACT_APP_GOOGLE_CLIENT_ID no está definido');
    throw new Error('REACT_APP_GOOGLE_CLIENT_ID no está definido');
}

if (!apiUrl) {
    console.error('ERROR: REACT_APP_API_URL no está definido');
    throw new Error('REACT_APP_API_URL no está definido');
}

// Log del entorno para debugging
console.log('Entorno:', process.env.NODE_ENV);
console.log('API URL:', apiUrl);
console.log('Frontend URL:', process.env.REACT_APP_FRONTEND_URL);

ReactDOM.render(
    <React.StrictMode>
        <GoogleOAuthProvider clientId={clientId}>
            <UserProvider>
                <App />
            </UserProvider>
        </GoogleOAuthProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
