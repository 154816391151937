import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import UserContext from '../../../../context/UserContext';
import EmailValidation from './EmailValidation';

const EmailValidationRoute = () => {
    const { userData, loading, fetchUserData } = useContext(UserContext);

    useEffect(() => {
        // Verificar el estado actual del usuario
        const verifyUserState = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                await fetchUserData(true); // Forzar actualización
            }
        };

        verifyUserState();
    }, [fetchUserData]);

    if (loading) {
        return <div>Loading...</div>;
    }

    console.log('Estado actual del usuario:', {
        userData,
        isAuthenticated: !!userData,
        isEmailVerified: userData?.isEmailVerified
    });

    // Si no hay usuario, redirigir al login
    if (!userData) {
        console.log('No hay datos de usuario, redirigiendo a login');
        return <Navigate to="/login" replace />;
    }

    // Si el email está verificado, redirigir a home
    if (userData.isEmailVerified) {
        console.log('Email verificado, redirigiendo a home');
        return <Navigate to="/home" replace />;
    }

    // Solo mostrar la validación si el email no está verificado
    return <EmailValidation />;
};

export default EmailValidationRoute;