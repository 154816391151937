import React, { useEffect, useState } from 'react';
import './ArticleSideInfo.css';

const ArticleSideInfo = ({ articleId }) => {
  const [transcriptionStatus, setTranscriptionStatus] = useState('processing');
  const [videoUrl, setVideoUrl] = useState('');
  const [events, setEvents] = useState([]);
  const [transcriptSegments, setTranscriptSegments] = useState([]);
  const [activeTab, setActiveTab] = useState('video');
  const [videoStatus, setVideoStatus] = useState('loading');
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSideInfo = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/articles/${articleId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Failed to fetch article information');
        }

        const data = await response.json();
        setVideoUrl(data.article.videoUrl || '');
        setEvents(data.article.events || []);
        setTranscriptionStatus(data.article.transcriptionStatus);
        setTranscriptSegments(data.article.transcriptSegments || []);
        setVideoStatus(data.article.videoUrl ? 'ready' : 'loading');
        setError(null);

        if (['completed', 'failed'].includes(data.article.transcriptionStatus)) {
          return true;
        }
        return false;
      } catch (error) {
        setError(error.message);
        return true;
      }
    };

    fetchSideInfo();

    const intervalId = setInterval(async () => {
      const shouldStop = await fetchSideInfo();
      if (shouldStop) {
        clearInterval(intervalId);
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [articleId]);

  const handleSegmentClick = (startTime) => {
    const videoElement = document.querySelector('video');
    if (videoElement) {
      videoElement.currentTime = startTime;
      videoElement.play();
    }
  };

  const formatTimestamp = (seconds) => {
    const date = new Date(seconds * 1000);
    const mm = String(date.getUTCMinutes()).padStart(2, '0');
    const ss = String(date.getUTCSeconds()).padStart(2, '0');
    return `${mm}:${ss}`;
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'video':
        return (
          <div className="info-box">
            {error && <div className="error-message">{error}</div>}
            {videoStatus === 'loading' ? (
              <div className="video-loading">
                <div className="loading-spinner"></div>
                <p>Uploading video...</p>
              </div>
            ) : videoUrl ? (
              <div className="video-container">
                <video controls>
                  <source src={videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            ) : (
              <p>No video available</p>
            )}
          </div>
        );
      case 'transcript':
        return (
          <div className="info-box">
            {error && <div className="error-message">{error}</div>}
            {transcriptionStatus === 'processing' ? (
              <p>Generating transcript...</p>
            ) : transcriptionStatus === 'failed' ? (
              <p>Failed to generate transcript</p>
            ) : transcriptSegments.length > 0 ? (
              <div className="transcript-segments">
                {transcriptSegments.map((segment, index) => (
                  <div 
                    key={index} 
                    className="transcript-segment"
                    onClick={() => handleSegmentClick(segment.startTime)}
                  >
                    <div className="transcript-row">
                      <span className="timestamp">
                        {formatTimestamp(segment.startTime)}
                      </span>
                      <p className="transcript-text">{segment.text}</p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p>No transcript available</p>
            )}
          </div>
        );
      case 'events':
        return (
          <div className="info-box">
            {error && <div className="error-message">{error}</div>}
            {events.length > 0 ? (
              <div className="events-list">
                {events.map((event, index) => (
                  <div key={index} className="event-row">
                    <span className="event-time">
                      {String(Math.floor(event.timestamp / 60)).padStart(2, '0')}:
                      {String(event.timestamp % 60).padStart(2, '0')}
                    </span>
                    <span className="event-type">
                      {event.type} {event.element}
                      {event.label && ` ${event.label}`}
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              <p>No events available</p>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="tabs-fill-container">
      <div className="tabs-fill">
        <button
          className={activeTab === 'video' ? 'active' : ''}
          onClick={() => setActiveTab('video')}
        >
          Video
        </button>
        <button
          className={activeTab === 'transcript' ? 'active' : ''}
          onClick={() => setActiveTab('transcript')}
        >
          Transcript
        </button>
        <button
          className={activeTab === 'events' ? 'active' : ''}
          onClick={() => setActiveTab('events')}
        >
          Events
        </button>
      </div>
      <div className="tab-fill-content">
        {renderTabContent()}
      </div>
    </div>
  );
};

export default ArticleSideInfo;
