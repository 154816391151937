import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import UserContext from '../../../context/UserContext';
import { GoogleLogin } from '@react-oauth/google';
import './Login.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { fetchUserData } = useContext(UserContext);
    const [searchParams] = useSearchParams();
    const invitationToken = searchParams.get('invitation');
    const reason = searchParams.get('reason');

    // Memoizar los parámetros de la URL
    const urlParams = useMemo(() => {
        const params = new URLSearchParams(location.search);
        return {
            isFromExtension: params.get('source') === 'extension',
            urlToken: params.get('token'),
            redirectUrl: params.get('redirect') || '/home'
        };
    }, [location.search]);

    // Optimizar el checkExistingToken
    useEffect(() => {
        const checkExistingToken = async () => {
            const { urlToken, isFromExtension, redirectUrl } = urlParams;
            const existingToken = localStorage.getItem('token');
            
            if (!urlToken && !existingToken) return;
            
            const token = urlToken || existingToken;
            try {
                if (!token.includes('.')) {
                    localStorage.removeItem('token');
                    return;
                }

                if (urlToken) {
                    localStorage.setItem('token', urlToken);
                }

                await fetchUserData();
                navigate(redirectUrl);

                if (token && isFromExtension) {
                    window.postMessage({ type: 'LOGIN_SUCCESS', token }, '*');
                }
            } catch (error) {
                localStorage.removeItem('token');
            }
        };
        
        checkExistingToken();
    }, [navigate, fetchUserData, urlParams]);

    useEffect(() => {
        if (reason === 'session_expired') {
            setErrorMessage('Your session has expired. Please log in again.');
        }
    }, [reason]);

    // Optimizar handleLoginSuccess
    const handleLoginSuccess = useCallback(async (token) => {
        try {
            localStorage.setItem('token', token);
            const apiUrl = process.env.REACT_APP_API_URL;
            const { isFromExtension } = urlParams;
            
            if (isFromExtension) {
                window.postMessage({ type: 'LOGIN_SUCCESS', token }, '*');
            }

            const userResponse = await fetch(`${apiUrl}/userdata`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                credentials: 'include'
            });

            if (!userResponse.ok) {
                throw new Error('Error fetching user data');
            }

            const updatedUserData = await userResponse.json();

            if (!updatedUserData.memberOf?.length) {
                navigate('/SignupWorkspace');
                return;
            }

            if (updatedUserData.memberOf.length === 1) {
                const workspaceId = updatedUserData.memberOf[0].workspace._id;
                
                const selectResponse = await fetch(`${apiUrl}/auth/select-workspace`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    credentials: 'include',
                    body: JSON.stringify({ workspaceId })
                });

                if (!selectResponse.ok) {
                    throw new Error('Error selecting workspace');
                }
                
                const selectData = await selectResponse.json();
                if (selectData.token) {
                    localStorage.setItem('token', selectData.token);
                    await fetchUserData(true);
                    navigate('/home');
                } else {
                    throw new Error('No token received after workspace selection');
                }
            } else {
                navigate('/workspace-selector');
            }

            if (invitationToken) {
                const inviteResponse = await fetch(`${apiUrl}/workspace/members/accept-invitation`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    credentials: 'include',
                    body: JSON.stringify({ invitationToken })
                });

                if (!inviteResponse.ok) {
                    throw new Error('Error accepting invitation');
                }

                const inviteData = await inviteResponse.json();
                if (inviteData.token) {
                    localStorage.setItem('token', inviteData.token);
                    await fetchUserData(true);
                }
            }
        } catch (error) {
            setErrorMessage('Error processing login: ' + error.message);
        }
    }, [navigate, fetchUserData, urlParams, invitationToken]);

    // Optimizar handleLogin
    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            setErrorMessage('');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ email, password }),
            });

            if (!response.ok) {
                throw new Error(await response.text());
            }

            const data = await response.json();

            if (data.token) {
                await handleLoginSuccess(data.token);
            } else {
                setErrorMessage(data.message || 'Error in login. Please try again.');
            }
        } catch (error) {
            setErrorMessage('An error occurred during login. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    // Optimizar handleGoogleSuccess
    const handleGoogleSuccess = useCallback(async (credentialResponse) => {
        try {
            setIsLoading(true);
            setErrorMessage('');
            
            if (!credentialResponse?.credential) {
                throw new Error('No credentials received from Google');
            }

            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/google-login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ credential: credentialResponse.credential }),
            });

            const data = await response.json();
            
            if (!response.ok) {
                throw new Error(data.message || 'Error in Google login');
            }

            if (data.token) {
                await handleLoginSuccess(data.token);
            } else {
                throw new Error('No token received from server');
            }
        } catch (error) {
            setErrorMessage(error.message || 'Error in Google login');
        } finally {
            setIsLoading(false);
        }
    }, [handleLoginSuccess]);

    return (
        <div className="login-container">
            <Helmet>
                <title>Login | KnowHub</title>
            </Helmet>
            <form className="login-form" onSubmit={handleLogin}>
                <h2 className="login-title">Log in to KnowHub</h2>
                
                {errorMessage && (
                    <div className="error-message">
                        {errorMessage}
                    </div>
                )}

                <div className="input-group">
                    <label className="input-group-label" htmlFor="login-email">
                        Your email
                    </label>
                    <input
                        className="input"
                        type="email"
                        id="login-email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        autoComplete="email"
                        placeholder="your@email.com"
                        disabled={isLoading}
                    />
                </div>

                <div className="input-group">
                    <label className="input-group-label" htmlFor="login-password">
                        Your password
                    </label>
                    <input
                        className="input"
                        type="password"
                        id="login-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        autoComplete="current-password"
                        placeholder="********"
                        disabled={isLoading}
                    />
                </div>
                <button 
                    className={`btn btn-fill-primary ${isLoading ? 'loading' : ''}`} 
                    type="submit"
                    disabled={isLoading}
                >
                    {isLoading ? 'Logging in...' : 'Login'}
                </button>
                
                <div className="social-login-container">
                    <p className="social-login-text">Or</p>
                    <div className="social-login">
                        <GoogleLogin
                            onSuccess={handleGoogleSuccess}
                            onError={() => setErrorMessage('Error in Google login. Please try again.')}
                            useOneTap={false}
                            cookiePolicy={'single_host_origin'}
                            disabled={isLoading}
                        />
                    </div>
                </div>
                <div className="login-actions">
                    <p className="login-actions-text">Don't have an account?</p>
                    <button 
                        className="btn btn-flat-primary" 
                        onClick={() => navigate('/signup')}
                        disabled={isLoading}
                    >
                        Sign Up
                    </button>
                </div>
            </form>
        </div>
    );
};

export default React.memo(Login);
