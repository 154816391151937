import React from 'react';
import '../../../styles/Modal.css';
import '../../../styles/Button.css';

const ModalDeleteLanguage = ({ showModal, closeModal, handleDeleteLanguage, language }) => {
    if (!showModal) return null;

    return (
        <div className="modal">
            <div className="modal-content">
            <h2>Confirm Deletion</h2>
                <p>
                    Are you sure you want to delete the language {language?.label}? <br />
                    You will lose all translations in this language across all articles.<br />
                    This action cannot be undone.
                </p>    
                
                <div className="modal-actions">
                    <button 
                        className="btn btn-outline-secondary" 
                        onClick={closeModal}
                    >
                        Cancel
                    </button>
                    <button 
                        className="btn btn-fill-danger" 
                        onClick={handleDeleteLanguage}
                    >
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ModalDeleteLanguage; 