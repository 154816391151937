import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import NavBar from '../Navbar/NavBar';
import ArticleTopBar from './components/TopBar/ArticleTopBar';
import ArticleSideInfo from './components/SideInfo/ArticleSideInfo';
import Tabs from './components/Tabs/Tabs';
import { WorkspaceContext } from '../../context/WorkspaceContext';
import UserContext from '../../context/UserContext';
import './ArticleDetail.css';

const ArticleDetail = () => {
  const { id } = useParams();
  const { workspaceData, workspaceLanguages, fetchWorkspaceData } = useContext(WorkspaceContext);
  const { userData } = useContext(UserContext);
  const [article, setArticle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchArticle = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/articles/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });

      if (response.ok) {
        const data = await response.json();
        return data.article;
      }
      throw new Error('Error al cargar el artículo');
    } catch (error) {
      console.error('Error al cargar el artículo:', error);
      return null;
    }
  }, [id]);

  useEffect(() => {
    let isMounted = true;

    const loadAllData = async () => {
      setIsLoading(true);
      try {
        const [, articleData] = await Promise.all([
          fetchWorkspaceData(),
          fetchArticle()
        ]);

        if (isMounted) {
          if (articleData) {
            setArticle(articleData);
          }
        }
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    loadAllData();

    return () => {
      isMounted = false;
    };
  }, [fetchWorkspaceData, fetchArticle]);

  if (isLoading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Cargando artículo...</p>
      </div>
    );
  }

  if (!workspaceData || !article) {
    return <div className="error-state">Error: No se pudieron cargar los datos.</div>;
  }

  return (
    <div className="container-horizontal">
      <Helmet>
        <title>{article.title} | KnowHub</title>
      </Helmet>
      <NavBar />
      <div className="main-content">
        <ArticleTopBar 
          backUrl="/articles" 
          title={article.title} 
          articleId={id}
          userRole={userData?.currentWorkspaceRole}
        />
        <div className="content">
          <div className="article-content">
            <Tabs 
              articleId={id} 
              languages={workspaceLanguages}
              userRole={userData?.currentWorkspaceRole} 
            />
          </div>
          <div className="article-side-info">
            <ArticleSideInfo articleId={id} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleDetail;
