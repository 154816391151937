import React from 'react';
import './Headers.css';

const Header = ({ title, description, actions }) => {
    return (
        <div className="header-tool">
            <div className="header-container">
                <div className="header-content">
                    <h1 className="header-title">{title}</h1>
                    <p className="header-description">
                        {description}
                    </p>
                </div>
                <div className="header-actions">
                    {actions.showCounter && (
                        <div className="header-counter">
                            <span className="header-count">{actions.count}</span>
                            <span className="header-count-label">{actions.countLabel}</span>
                        </div>
                    )}
                    {actions.showButton && (
                        <button 
                            className="btn btn-fill-primary"
                            onClick={actions.buttonAction}
                            disabled={actions.buttonDisabled}
                        >
                            {actions.buttonText}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Header;