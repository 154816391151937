import React from 'react';
import { Trash2 } from 'lucide-react';
import '../../../../styles/DropdownMenu.css';
import './ActionsDropdownMenu.css';

const ActionsDropdownMenu = ({ onClose, onDelete }) => {
    const handleDelete = () => {
        onDelete();
        onClose();
    };

    return (
        <div className="dropdown actions-dropdown">
            <button 
                className="dropdown-item"
                onClick={handleDelete}
            >
                <Trash2 size={20} />
                <span>Delete article</span>
            </button>
        </div>
    );
};

export default ActionsDropdownMenu;
