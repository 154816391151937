import React from 'react';
import { SquareCheck, Trash2, X } from 'lucide-react';
import './TableTools.css';

const TableTools = ({ selectedCount, onRemove, onClose }) => {
    return (
        <div className="table-tools">
            <div className="table-tools-content">
                <div className="selection-info">
                    <SquareCheck size={16} className="icon" />
                    <div className="selection-text">
                        <span className="selection-count">{selectedCount}</span>
                        <span className="selection-label">Select</span>
                    </div>
                </div>

                <div className="divider" />

                <button 
                    className="btn btn-flat-danger"
                    onClick={() => onRemove()}
                >
                    <Trash2 size={20} />
                    <span>Eliminar</span>
                </button>

                <div className="divider" />

                <button 
                    className="close-button"
                    onClick={onClose}
                >
                    <X size={20} />
                </button>
            </div>
        </div>
    );
};

export default TableTools; 