import React, { useEffect, useState, useCallback, useRef } from 'react';
import ReactQuill from 'react-quill';
import DOMPurify from 'dompurify';
import 'react-quill/dist/quill.snow.css';
import './EditableContent.css';
import ConfirmReviewModal from '../Modals/ConfirmReviewModal';
import TranslationErrorModal from '../Modals/TranslationErrorModal';

const EditableContent = ({ articleId }) => {
  const [editableContent, setEditableContent] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isContentGenerating, setIsContentGenerating] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [contentStatus, setContentStatus] = useState('Ready for review');
  const [showModal, setShowModal] = useState(false);
  const [showTranslationErrorModal, setShowTranslationErrorModal] = useState(false);
  const [error, setError] = useState(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [contentGenerationStatus, setContentGenerationStatus] = useState('pending');
  const [saveRetryCount, setSaveRetryCount] = useState(0);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  
  const quillRef = useRef(null);
  const changeRef = useRef(null);
  const saveIntervalRef = useRef(null);
  const saveRetryTimeoutRef = useRef(null);

  const fetchEditableContent = useCallback(async () => {
    try {
      console.log('Verificando API_URL:', process.env.REACT_APP_API_URL);
      const apiUrl = `${process.env.REACT_APP_API_URL}/articles/${articleId}`;
      console.log('Cargando contenido desde:', apiUrl);
      
      const response = await fetch(apiUrl, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        credentials: 'include'
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error al cargar contenido:', {
          status: response.status,
          statusText: response.statusText,
          errorText,
          url: apiUrl
        });
        throw new Error(`Failed to fetch content: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      const article = data.article;

      setContentGenerationStatus(article.contentStatus);

      if (article.contentStatus === 'completed' && article.editableContent) {
        const sanitizedContent = DOMPurify.sanitize(
          article.editableContent.content || article.content
        );

        if (!initialLoadComplete) {
          setEditableContent(sanitizedContent);
          setInitialLoadComplete(true);
        }
        
        setContentStatus(article.editableContent.status || 'Ready for review');
        setIsContentGenerating(false);
      } else {
        setIsContentGenerating(true);
      }
    } catch (error) {
      console.error('Error detallado al cargar contenido:', {
        message: error.message,
        stack: error.stack,
        articleId
      });
      setError(`Error al cargar: ${error.message}. Por favor, recarga la página.`);
      setIsContentGenerating(false);
    }
  }, [articleId, initialLoadComplete]);

  useEffect(() => {
    fetchEditableContent();
    
    const intervalId = setInterval(() => {
      if (!initialLoadComplete) {
        fetchEditableContent();
      }
    }, 5000);

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [fetchEditableContent, initialLoadComplete]);

  const saveContent = useCallback(async (force = false) => {
    if (!force && (!isEditing || !changeRef.current || changeRef.current.ops.length === 0)) return;
    
    // No intentar guardar si no hay conexión a internet
    if (!navigator.onLine) {
      console.log('Sin conexión a internet. No se puede guardar en este momento.');
      setError('Sin conexión a internet. Los cambios se guardarán cuando la conexión se restablezca.');
      return;
    }

    setIsSaving(true);
    try {
      console.log('=== INICIO GUARDADO ===');
      console.log('Guardando contenido...', {
        articleId,
        contentLength: editableContent.length,
        hasChanges: changeRef.current?.ops.length > 0,
        forzado: force,
        isEditing,
        intentoNúmero: saveRetryCount + 1
      });

      // Mostrar detalles completos del contenido a guardar para depuración
      console.log('Contenido a guardar (primeros 100 caracteres):', editableContent.substring(0, 100) + '...');
      const jsonBody = JSON.stringify({ content: editableContent });
      console.log('Body de la petición (primeros 100 caracteres):', jsonBody.substring(0, 100) + '...');
      console.log('Tamaño total del body:', jsonBody.length, 'bytes');

      const apiUrl = `${process.env.REACT_APP_API_URL}/articles/${articleId}/editableContent`;
      console.log('Enviando petición PUT a:', apiUrl);
      
      // Comprobar token
      const token = localStorage.getItem('token');
      console.log('Token disponible:', !!token);
      
      // Mostrar todas las opciones de fetch para depuración
      const fetchOptions = {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: jsonBody,
      };
      console.log('Opciones de fetch:', JSON.stringify(fetchOptions, (key, value) => {
        // No mostrar el body completo para evitar llenar la consola
        if (key === 'body') return 'BODY-CONTENT'; 
        return value;
      }, 2));
      
      console.log('Iniciando petición fetch...');
      const response = await fetch(apiUrl, fetchOptions);
      console.log('Petición fetch completada');

      console.log('Respuesta recibida:', {
        status: response.status,
        statusText: response.statusText,
        ok: response.ok,
        headers: Object.fromEntries([...response.headers.entries()])
      });

      if (!response.ok) {
        let errorText = '';
        try {
          errorText = await response.text();
        } catch (e) {
          errorText = 'No se pudo leer el cuerpo de la respuesta: ' + e.message;
        }
        
        console.error('Error en la respuesta del servidor:', {
          status: response.status,
          statusText: response.statusText,
          errorText,
          url: apiUrl
        });
        throw new Error(`Failed to save content: ${response.status} ${response.statusText}`);
      }

      console.log('Contenido guardado con éxito');

      // Limpiamos cualquier error previo
      setError(null);
      
      // Resetear contador de intentos
      if (saveRetryCount > 0) {
        setSaveRetryCount(0);
      }

      // Resetear Delta después de guardar con éxito
      if (changeRef.current) {
        changeRef.current = quillRef.current.getEditor().constructor.import('delta')();
      }
      setIsEditing(false);
      console.log('=== FIN GUARDADO ===');
    } catch (error) {
      console.error('Error detallado al guardar contenido:', {
        name: error.name,
        message: error.message,
        stack: error.stack,
        articleId,
        intentoNúmero: saveRetryCount + 1
      });
      
      // Intentar de nuevo si es un error de red o del servidor
      if (saveRetryCount < 3) {
        const nextRetryCount = saveRetryCount + 1;
        setSaveRetryCount(nextRetryCount);
        
        // Retrasos exponenciales: 5s, 10s, 20s
        const retryDelay = Math.pow(2, nextRetryCount) * 2500;
        console.log(`Reintentando guardado en ${retryDelay/1000} segundos...`);
        
        // Limpiar timeout anterior si existe
        if (saveRetryTimeoutRef.current) {
          clearTimeout(saveRetryTimeoutRef.current);
        }
        
        // Configurar nuevo timeout para reintento
        saveRetryTimeoutRef.current = setTimeout(() => {
          if (changeRef.current && changeRef.current.ops && changeRef.current.ops.length > 0) {
            console.log(`Reintentando guardado automático (intento ${nextRetryCount + 1})...`);
            saveContent(true);
          }
        }, retryDelay);
        
        setError(`Error al guardar: ${error.message}. Reintentando automáticamente...`);
      } else {
        setError(`Error al guardar después de varios intentos: ${error.message}. Por favor, utiliza el botón "Guardar manualmente".`);
        setSaveRetryCount(0);
      }
    } finally {
      setIsSaving(false);
    }
  }, [articleId, editableContent, isEditing, saveRetryCount]);

  // Manejo de estado de conexión
  useEffect(() => {
    const handleOnline = () => {
      console.log('Conexión restablecida');
      setIsOnline(true);
      
      // Intentar guardar cambios pendientes cuando vuelve la conexión
      if (changeRef.current && changeRef.current.ops && changeRef.current.ops.length > 0) {
        console.log('Intentando guardar cambios pendientes después de reconexión');
        saveContent(true);
      }
    };
    
    const handleOffline = () => {
      console.log('Conexión perdida');
      setIsOnline(false);
    };
    
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [saveContent]);

  // Configuración del autosave usando la API nativa de Quill
  useEffect(() => {
    if (!quillRef.current || !initialLoadComplete) return;

    try {
      const quill = quillRef.current.getEditor();
      if (!quill) {
        console.error('No se pudo obtener la instancia de Quill');
        return;
      }

      // Importar Delta desde Quill
      const Delta = quill.constructor.import('delta');
      if (!Delta) {
        console.error('No se pudo importar Delta de Quill');
        return;
      }
      
      // Configurar para acumular cambios
      changeRef.current = new Delta();

      // Escuchar cambios en el texto
      const textChangeHandler = (delta, oldContents, source) => {
        console.log('Editor changed - source:', source, 'delta:', delta);
        
        if (changeRef.current) {
          changeRef.current = changeRef.current.compose(delta);
          
          // Solo marcar como editando si hay cambios reales
          if (delta.ops && delta.ops.length > 0) {
            console.log('Cambios detectados en el editor!', delta.ops);
            if (!isEditing) {
              console.log('Cambiando estado a isEditing');
              setIsEditing(true);
            }
          }
        }
      };

      quill.on('text-change', textChangeHandler);

      // Configurar intervalo para guardar periódicamente
      saveIntervalRef.current = setInterval(() => {
        if (isOnline && changeRef.current && changeRef.current.ops && changeRef.current.ops.length > 0) {
          console.log('Guardado automático iniciado por intervalo');
          saveContent();
        }
      }, 5000); // Guardar cada 5 segundos si hay cambios

      return () => {
        quill.off('text-change', textChangeHandler);
        if (saveIntervalRef.current) {
          clearInterval(saveIntervalRef.current);
        }
        if (saveRetryTimeoutRef.current) {
          clearTimeout(saveRetryTimeoutRef.current);
        }
        
        // Guardar cualquier cambio pendiente al desmontar
        if (changeRef.current && changeRef.current.ops && changeRef.current.ops.length > 0) {
          saveContent();
        }
      };
    } catch (err) {
      console.error('Error al inicializar el editor:', err);
      setError('Error al configurar el editor. Por favor recarga la página.');
    }
  }, [initialLoadComplete, isEditing, isOnline, saveContent]);

  const handleContentChange = (content) => {
    // Solo actualizamos el estado local, los cambios reales son trackados por Quill
    const sanitizedContent = DOMPurify.sanitize(content.trim());
    if (sanitizedContent !== editableContent) {
      setEditableContent(sanitizedContent);
    }
  };

  // Guardar al abandonar la página
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (changeRef.current && changeRef.current.ops && changeRef.current.ops.length > 0) {
        // Intentar guardar
        saveContent();
        
        // Mostrar confirmación al usuario
        e.preventDefault();
        e.returnValue = '¿Seguro que quieres abandonar la página? Hay cambios sin guardar.';
        return e.returnValue;
      }
    };

    const handleVisibilityChange = () => {
      if (document.hidden && changeRef.current && changeRef.current.ops && changeRef.current.ops.length > 0) {
        saveContent();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      
      // Guardar al desmontar si hay cambios
      if (changeRef.current && changeRef.current.ops && changeRef.current.ops.length > 0) {
        saveContent();
      }
    };
  }, [saveContent]);

  const handleVerify = async () => {
    // Asegurar que todos los cambios estén guardados
    await saveContent();
    
    try {
      const reviewResponse = await fetch(`${process.env.REACT_APP_API_URL}/articles/${articleId}/editableContent/status`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ status: 'Reviewed' }),
      });

      if (!reviewResponse.ok) {
        throw new Error('Failed to mark content as reviewed');
      }

      const translationResponse = await fetch(`${process.env.REACT_APP_API_URL}/articles/${articleId}/translate`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        credentials: 'include'
      });

      if (!translationResponse.ok) {
        throw new Error('Failed to start translation process');
      }

      setContentStatus('Reviewed');
      setShowModal(false);
    } catch (error) {
      console.error('Error in review and translation process:', error);
      setError(error.message);
      setShowTranslationErrorModal(true);
    }
  };

  const handleTranslation = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/articles/${articleId}/translate`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Failed to translate content');
      }

      setContentStatus('Reviewed');
      setShowTranslationErrorModal(false);
    } catch (error) {
      console.error('Error translating content:', error);
      setError(error.message);
      setShowTranslationErrorModal(true);
    }
  };

  const handleOpenModal = async () => {
    await saveContent();
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmReview = () => {
    setShowModal(false);
    handleVerify();
  };

  // Función para verificar si hay cambios sin guardar
  const hasUnsavedChanges = useCallback(() => {
    if (!changeRef.current) return false;
    return changeRef.current.ops && changeRef.current.ops.length > 0;
  }, []);

  return (
    <div className="editable-content">
      {error && <div className="error-message">{error}</div>}
      {!isOnline && (
        <div className="connection-warning">
          Trabajando sin conexión. Los cambios se guardarán cuando vuelva la conexión a internet.
        </div>
      )}
      {isContentGenerating ? (
        <div className="loading-state">
          {contentGenerationStatus === 'pending' && (
            <p>Esperando inicio de generación del contenido...</p>
          )}
          {contentGenerationStatus === 'processing' && (
            <p>El contenido se está generando, por favor espera...</p>
          )}
          {contentGenerationStatus === 'failed' && (
            <p>Error en la generación del contenido. Por favor, contacta al soporte.</p>
          )}
        </div>
      ) : (
        <>
          <div className="editor-container">
            <ReactQuill
              ref={quillRef}
              value={editableContent}
              onChange={handleContentChange}
              modules={{
                toolbar: [
                  [{ 'header': [1, 2, 3, 4, 5, false] }],
                  ['bold', 'italic', 'underline'],
                  ['link'],
                  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                  ['clean'],
                ],
                clipboard: {
                  matchVisual: false,
                },
              }}
              className="quill-editor"
            />
          </div>
          <div className="footer">
            <span className="status-text">
              {isSaving 
                ? 'Guardando cambios...' 
                : hasUnsavedChanges() 
                  ? 'Cambios pendientes de guardar' 
                  : 'Todos los cambios guardados'}
              {saveRetryCount > 0 && ` (Reintento ${saveRetryCount}/3)`}
            </span>
            <div className="button-group">
              <button 
                className="save-manual-btn" 
                onClick={() => saveContent(true)}
                disabled={isSaving || !isOnline}
              >
                {isSaving ? 'Guardando...' : 'Guardar manualmente'}
              </button>
              <button 
                className="btn-fill-primary" 
                onClick={handleOpenModal} 
                disabled={contentStatus === 'Reviewed' || !isOnline}
              >
                {contentStatus === 'Reviewed' ? 'Revisado' : 'Marcar como Revisado y Traducir'}
              </button>
            </div>
          </div>
          <ConfirmReviewModal
            showModal={showModal}
            closeModal={handleCloseModal}
            handleConfirm={handleConfirmReview}
          />
          <TranslationErrorModal
            showModal={showTranslationErrorModal}
            closeModal={() => setShowTranslationErrorModal(false)}
            handleTryAgain={handleTranslation}
          />
        </>
      )}
    </div>
  );
};

export default EditableContent;
